import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WaterReportmeterModel } from '../modles/waterreport.model';
@Injectable({
    providedIn: 'root'
})
export class WaterReportService {

    private baseUrl = environment.waterMeter + 'api/Watermeter/';
    constructor(private httpClient: HttpClient) { }

    private WaterReportreatedSubject = new Subject<any>();
    WaterReportreatedObservable = this.WaterReportreatedSubject.asObservable();

    private WaterReportEditSubject = new Subject<any>();
    WaterReportActionObservable = this.WaterReportEditSubject.asObservable();

    addWaterReport(WaterReport: WaterReportmeterModel) {
        return this.httpClient.post(this.baseUrl+'SaveWatermeter', WaterReport);
    }
    updateWatermeter(WaterReport: WaterReportmeterModel) {
        return this.httpClient.post(this.baseUrl+'UpdateWatermeter', WaterReport);
    }
    getallWaterReportList(date:string) {
        return this.httpClient.get(this.baseUrl +  "/GetAllWatermeterValues?date="+date);
    }
    getallWaterReportbyUniId(date:string,UnitID:number) {
        return this.httpClient.get(this.baseUrl +  "/GetAllWatermeterValuesByUnit?date="+date+"&UnitID="+UnitID);
    }
    getallWaterReportsById(UnitId:number) {
        return this.httpClient.get(this.baseUrl +  "/GetWatermeterValuesbyUnitiD?UnitId="+UnitId);
    }
    
    notifyWaterReportreated() {
        this.WaterReportreatedSubject.next(true);
    }
    editWaterReportRequest(superID: number) {
        this.WaterReportEditSubject.next(superID);
    }
    updateStatus(superID:number,status:string){
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID+'&status='+status);

    }
    uploadDocument(fromdata: FormData){
        return this.httpClient.post(this.baseUrl + '/uploadPhoto', fromdata, { observe: 'response' });
    }
}
