import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MdocsSafteymodel } from '../models/mdocssaftey.models';
@Injectable({
    providedIn: 'root'
})
export class MdocsSafteyService {

    private baseUrl = environment.waterMeter;
    constructor(private httpClient: HttpClient) { }

    private MdocsSafteyCreatedSubject = new Subject<any>();
    MdocsSafteyreatedObservable = this.MdocsSafteyCreatedSubject.asObservable();

    private MdocsSafteyEditSubject = new Subject<any>();
    MdocsSafteyActionObservable = this.MdocsSafteyEditSubject.asObservable();

    addMdocsSaftey(fromdata: FormData) {
        return this.httpClient.post(this.baseUrl + 'api/Mdocs/SafteySave', fromdata, );
    }
    GetMdocsSafteyList(UnitID: number,Status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/GetSafteyList?unitId="+UnitID+"&Status="+Status);
    }
    UpdateStatus(Id: number,status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/UpdateSafteyStatus?Id="+Id+"&Status="+status);
    }

    notifyMdocsSafteyreated() {
        this.MdocsSafteyCreatedSubject.next(true);
    }
    editMdocsSafteyRequest(superID: number) {
        this.MdocsSafteyEditSubject.next(superID);
    }
  
}
