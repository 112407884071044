import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Login } from './auth/model/login.model';
import { HttpClient } from '@angular/common/http';
import { Employee } from './admin/models/employee.model';
import { Subject, Observable,BehaviorSubject } from 'rxjs';
import { ResetPassword } from './auth/model/resetPassword.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn: boolean;
  tokenValid: boolean;
  isAdmin: boolean;
  private roleIdSubject  = new Subject<number>();
  roleIdObservable: Observable<number> = this.roleIdSubject.asObservable();

  private LOGIN_API_URL = environment.adminApiUrl + 'api/Employee/Login';
  

  constructor(private router: Router, private httpClient: HttpClient) { }

  login(loginModel: Login) {
    console.log(loginModel);
    return this.httpClient.post(this.LOGIN_API_URL , loginModel);
  }

  handleAuth() {
    // When Auth0 hash parsed, get profile
    /*this._auth0.parseHash(window.location.href, (err, authResult) => {

     if (err) {
        this._clearRedirect();
        this.router.navigate(['/']);
        console.error(`Error authenticating: ${err.error}`);
      }
    });
    */
  }

    getUser() {
        const userStr = sessionStorage.getItem('user');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  }

  setUser(employee: Employee) {
    if (employee) {
      const userStr = JSON.stringify(employee);
        sessionStorage.setItem('user', userStr);
        sessionStorage.setItem("Loginstatus","1");
      return true;
    }
    return false;
  }

  private _getProfile(authResult) {


  }
  checkLoginStatus(){
    var logincookie=sessionStorage.getItem("Loginstatus");
    if(logincookie=="1"){
      return true;
    }
    return false;
  }

  isUserExist() {
      if (sessionStorage.getItem('Loginstatus')=="1") {
      return true;
    }
    return false;
  }


  private _redirect() {
      const redirect = decodeURI(sessionStorage.getItem('authRedirect'));
    const navArr = [redirect || '/'];

    this.router.navigate(navArr);
    // Redirection completed; clear redirect from storage
    this._clearRedirect();
  }

  private _clearRedirect() {
      sessionStorage.removeItem('user');
  }

  logout() {

    this._clearRedirect();
    // this.router.navigate(['login']);
  }

  setUserMenu(roleId: number) {
    this.roleIdSubject.next(roleId);
  }

  forgotPassword(email: string) {
    return this.httpClient.get(environment.adminApiUrl +'api/admin/employee/forgotPassword/' + email);
  }

  resetPassword(resetPassword:ResetPassword) {
    return this.httpClient.post(environment.adminApiUrl +'api/admin/employee/resetPassword',resetPassword);
  }
  private logOutSubject = new Subject<any>();
    logOutActionObservable = this.logOutSubject.asObservable();

    logOutRequest() {
      this.logOutSubject.next();
  }
  
   behaviour =new BehaviorSubject("login");

}
