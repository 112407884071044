import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Organization } from './models/organization.model';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  baseUrl = environment.adminApiUrl + 'api/Organiser';
  //baseUrl = 'http://localhost:53806/api/Organiser';

  private orgCreatedSubject = new Subject<any>();
  orgCreatedObservable = this.orgCreatedSubject.asObservable();

  private orgEditSubject = new Subject<any>();
  orgEditObservable = this.orgEditSubject.asObservable();

  constructor(private httpClient: HttpClient) { }

  addOrganization(organizationData: Organization) {
 
    return this.httpClient.post(this.baseUrl + "/Save", organizationData);
  }

  getOrganizations() {
    return this.httpClient.get(this.baseUrl + "/GetAllOrganiser");
  }

  getOrganization(orgId: number) {
    return this.httpClient.get(this.baseUrl + '/GetOrganiserbyID?orgId=' + orgId);
  }

  getParentOrganizations() {
    return this.httpClient.get(this.baseUrl + '/GetParentOrganiser');
  }

  getOrganizationUnits() {

    return this.httpClient.get(this.baseUrl + "/GetOrganiser");
  }

  notifyOrganizationCreated() {
    this.orgCreatedSubject.next(true);
  }

  statusUpdationOrganization(orgId: number) {
    return this.httpClient.delete(this.baseUrl + '/OrganiserChangeStatus?Oid=' + orgId);
  }
  editOrginization(orgId: number) {
    this.orgEditSubject.next(orgId);
  }
  getOrgDetails(orgId: number) {
    return this.httpClient.get(this.baseUrl + '/' + orgId, {});
  }

  getOrgForEdit(orgId: number) {
    throw new Error('Method not implemented.');
  }

}
