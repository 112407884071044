import { Component, OnInit } from '@angular/core';
import { Employee } from '../../models/employee.model';
import { EmployeeService } from '../../employee.service';
import { Address } from '../../models/address.model';
import { MessageService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-external-employee',
  templateUrl: './external-emp.component.html',
  styleUrls: ['./external-emp.component.css']
})
export class ExternalEmployeeComponent implements OnInit {
  empData: Employee;
  index = 0;
  showTabs;
  constructor(private empService: EmployeeService, private messageService: MessageService,private spinnerService: Ng4LoadingSpinnerService) {
    this.empData = new Employee();
   }

   addedGeneralValues(genralData: Employee) {
    console.log('genralData:::::', genralData);
    this.empData = genralData;
    this.index = 1;
  }

  // addPermanentAddress(addressData: Address) {
  //   addressData.addrType = 'permanent';
  //   if (!this.empData.addresses) {
  //     this.empData.addresses = [];
  //   }
  //   this.empData.addresses.push(addressData);
  //   console.log('AddressDdata', this.empData);
  // }

  addCommunicationAddress(addressData: Address) {
    this.spinnerService.show();
      addressData.addrType = 'communication';
    
    // if (!this.empData.addresses) {
    //   this.empData.addresses = [];
    // }else{
   
    // }
    this.empData.addressId=addressData.addressId;
    this.empData.city=addressData.city;
    this.empData.state=addressData.state;
    this.empData.zipCode=addressData.zipCode;
    this.empData.addressLine1=addressData.addressLine1;
    this.empData.addressLine2=addressData.addressLine2;
    this.empData.country=addressData.country;

if(this.empData.employeeId!=0){
    this.empService.addInternalEmployee(this.empData).subscribe((response: any) => {
        if (response) {
            this.messageService.add({ severity: 'success', summary: 'Employee added successfully', detail: '', sticky: false});
          this.empService.notifyEmployeeCreated();
        }
        this.index = 0;
    });
  }else{
    this.empService.EmployeeUpdate(this.empData).subscribe((response: any) => {
      if (response) {
          this.messageService.add({ severity: 'success', summary: 'Employee Update successfully', detail: '', sticky: false});
        this.empService.notifyEmployeeCreated();
      }
      this.index = 0;
  });
  }
   }

  ngOnInit() {
    this.showTabs = {
      one: true,
      two: true,
      three: true
    };
  }

}
