import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MdocsSopsmodel } from '../models/mdocssops.models';
@Injectable({
    providedIn: 'root'
})
export class MdocsSopsService {

    private baseUrl = environment.waterMeter;
    constructor(private httpClient: HttpClient) { }

    private MdocsSopsCreatedSubject = new Subject<any>();
    MdocsSopsreatedObservable = this.MdocsSopsCreatedSubject.asObservable();

    private MdocsSopsEditSubject = new Subject<any>();
    MdocsSopsActionObservable = this.MdocsSopsEditSubject.asObservable();

    addMdocsSops(fromdata: FormData) {
        return this.httpClient.post(this.baseUrl + 'api/Mdocs/SopsSave', fromdata, );
    }
    GetMdocsSopsList(UnitID: number,Status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/GetSopsList?unitId="+UnitID+"&Status="+Status);
    }
    UpdateStatus(Id: number,status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/UpdateSopsStatus?Id="+Id+"&Status="+status);
    }
    notifyMdocsSopsreated() {
        this.MdocsSopsCreatedSubject.next(true);
    }
    editMdocsSopsRequest(superID: number) {
        this.MdocsSopsEditSubject.next(superID);
    }
   
   
}
