import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery'
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-meterwisegraph',
    templateUrl: './meterwisegraph.component.html',
    styleUrls: ['./meterwisegraph.component.css']
})
export class MeterwisegraphComponent implements OnInit {
    private chart: AmChart;
    fromDate: Date;
    toDate: Date;
    constructor(private AmCharts: AmChartsService) {

    }
    ShowChart() {

        var fromdate = this.formatDate(this.fromDate);
        var toDate = this.formatDate(this.toDate);
        this.chart = this.AmCharts.makeChart("chartdiv", {
            "type": "serial",
            "theme": "light",
            "dataLoader": {
                "url": environment.waterMeter + "/api/Consumption/GetMeterWiseGraph?fromdate=" + fromdate + "&todate=" + toDate,
                "format": "json",
                "showErrors": true,
                "noStyles": true,
                "async": true,
                "type": "GET",
            },
            "valueAxes": [{
                "gridColor": "#FFFFFF",
                "gridAlpha": 1,
                "dashLength": 0,
                "title": "Cubic Meters"
            }],
            "gridAboveGraphs": true,
            "startDuration": 1,
            "graphs": [{
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.8,
                "type": "column",
                "valueField": "cons"
            }],
            "chartCursor": {
                "categoryBalloonEnabled": false,
                "cursorAlpha": 0,
                "zoomable": false
            },
            "categoryField": "unitname",
            "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "tickLength": 5,
                "labelRotation": 45,
                "title": "Unit Names"
            },
            "export": {
                "enabled": true
            }

        });
    }

    ngOnInit() {

    }
    ngAfterViewInit() {

    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
    }
    formatDate(date) {
        if (date != undefined) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        return null;
    }
}
