import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MdocsPptmodel } from '../models/mdocsppt.models';
@Injectable({
    providedIn: 'root'
})
export class MdocsPptService {

    private baseUrl = environment.waterMeter;
    constructor(private httpClient: HttpClient) { }

    private MdocsPptCreatedSubject = new Subject<any>();
    MdocsPptreatedObservable = this.MdocsPptCreatedSubject.asObservable();

    private MdocsPptEditSubject = new Subject<any>();
    MdocsPptActionObservable = this.MdocsPptEditSubject.asObservable();

    addMdocsPpt(fromdata: FormData) {
        return this.httpClient.post(this.baseUrl + 'api/Mdocs/PptSave', fromdata, );
    }
    GetMdocsPptList(UnitID: number,Status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/GetPptList?unitId="+UnitID+"&Status="+Status);
    }
    UpdateStatus(Id: number,status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/UpdatePptStatus?Id="+Id+"&Status="+status);
    }

    notifyMdocsPptreated() {
        this.MdocsPptCreatedSubject.next(true);
    }
    editMdocsPptRequest(superID: number) {
        this.MdocsPptEditSubject.next(superID);
    }
    updateStatus(superID: number, status: string) {
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID + '&status=' + status);

    }
   
}
