import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnitwatermeterModel, Unittypemodel, Consumertypemodel, UnitwatermeterlistModel } from '../../modles/unitwatermeter.model';
import { UnitWaterService } from '../../services/unitwatermeter.services';
import { EffluentwatermeterService } from '../../services/effluentwatermeter.services';
import { EffluentwatermeterModel } from '../../modles/effluentwatermeter.model';
import { MessageService } from 'primeng/api';
import { Parameter } from '../../../admin/models/parameter.model';
import { ParameterService } from '../../../admin/parameter.service';
import { Organization } from '../../../admin/models/organization.model';
import { OrganizationService } from '../../../admin/organization.service';
@Component({
  selector: 'app-form-effluent',
  templateUrl: './form-effluent.component.html',
  styleUrls: ['./form-effluent.component.css']
})
export class FormEffluentComponent implements OnInit {
  effForm: FormGroup;
  @Input() disabled = false;
  submitted = false; requiredmsg = false;
  selectedeffId = 0; status = true;
  unittypes: Unittypemodel[] = [];
  consumertypes: Consumertypemodel[] = [];
  unitwaterlst: UnitwatermeterlistModel[] = [];
  departments: Array<Parameter>;
  organizations: Array<Organization>;
  constructor(private organizationService: OrganizationService, private parameterService: ParameterService, private fb: FormBuilder, private effservice: EffluentwatermeterService, private unitservice: UnitWaterService, private messageService: MessageService) { }

  ngOnInit() {
    this.createForm();
    this.getDepartments();
    this.retrieveunitdetails();
    this.getOrganizations();
    this.effservice.EffluentwatermeterActionObservable.subscribe((effId: number) => {
      if (effId) {
        this.selectedeffId = effId;
        this.effservice.GetEffluent(effId).subscribe((effmodel: EffluentwatermeterModel) => {
          console.log(effmodel);
          this.populateroadTaxtDetais(effmodel);

        });
      }
      this.requiredmsg = false;
    });
  }
  createForm() {
    this.effForm = this.fb.group({
      //vendorId: ['', Validators.required],
      Unit_ID: ['', Validators.required],
      Effluent: ['', Validators.required],
      Description: ['', Validators.required],

    });

    if (this.effForm.valid) {
      this.disabled = true;
    }
  }
  formreset() {
    this.effForm.reset();
    this.effForm.get("Unit_ID").setValue('');
  }
  retrieveunitdetails() {
    this.unitservice.getallUnitWaterList().subscribe((resp: any) => {
      if (resp) {
        this.unitwaterlst = resp.UnitMasterList;
      }
    });
  }
  populateroadTaxtDetais(getDetails: EffluentwatermeterModel) {
    //this.roadtaxForm.get("vendorId").setValue(getDetails.vendorId);
    this.effForm.get("Unit_ID").setValue(getDetails.Unit_ID);
    //const roadTaxValidity = new Date(getDetails.roadTaxValidity);
    this.effForm.get("Effluent").setValue(getDetails.Effluent);
    this.effForm.get("Description").setValue(getDetails.Description + "");
    this.selectedeffId = getDetails.Effluent_ID;
    this.status = getDetails.Status;

  }
  getDepartments() {
    this.parameterService.getDepartments().subscribe((response: any) => {
      this.departments = response.Configlst;
    });
  }
  registerEff() {

    if (this.effForm.valid) {
      this.requiredmsg = false;
      const EffData = this.effForm.value;
      EffData.Effluent_ID = this.selectedeffId;
      EffData.Status = this.status;
      this.effservice.addEffluentwatermeter(EffData).subscribe((response: any) => {
        console.log(response);
        var statusval = this.selectedeffId == 0 ? "added" : "update";
        this.messageService.add({ severity: 'success', summary: 'Water Effluent ' + statusval + ' successfully', detail: '', sticky: false });
        this.selectedeffId = 0;
        this.formreset();
        this.effservice.notifyEffluentwatermeterreated();
      });
    }
  }
  getOrganizations() {
    this.organizationService.getOrganizationUnits().subscribe((response: any) => {
      this.organizations = response.MOrganiserDetails;
    });
  }
}

