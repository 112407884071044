import { Component, ViewChild, OnInit, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleService } from '../role.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Role } from '../models/role.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit, OnDestroy, AfterViewInit {
  roleForm: FormGroup;
  roles: Array<Role>;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();


  constructor(private fb: FormBuilder, private roleService: RoleService, private http: HttpClient, private messageService: MessageService) {
    this.createRoleForm();
  }
  createRoleForm() {
    this.roleForm = this.fb.group({
      roleId: [],
      roleName: ['', [Validators.required, Validators.min(5), Validators.max(150)]],
      roleDesc: ['', [Validators.required, Validators.min(5), Validators.max(150)]],
      // status: ['', Validators.required]
    });
  }
  getAllRoles() {
    this.roleService.retrieveAllRoles().subscribe((response: any) => {
      console.log(response);
      this.roles = response.MRollsListDetails;
      this.rerender();
    });
  }
  ngOnInit() {
    this.roles = [];
    this.getAllRoles();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Roles',
          filename: 'Roles-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0,1, 2, 3]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Roles',
          filename: 'Roles-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0,1, 2, 3]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  addRole() {
    if (this.roleForm.valid) {
      var roleval = String(this.roleForm.get("roleName").value);
      var roleid = String(this.roleForm.get("roleId").value);
      var roleflag = true;
      for (const role of this.roles) {
        if (role.roleName.toLowerCase() === roleval.toLowerCase() && roleid==null) {
          this.messageService.add({ severity: 'error', summary: 'This role already exist.', detail: '', sticky: false });
          roleflag = false;
        }
      }
      if (roleflag) {
        this.roleService.addRole(this.roleForm.value).subscribe((response: Role) => {
          this.messageService.add({ severity: 'success', summary: 'Role added successfully.', detail: '', sticky: false });
          this.getAllRoles();
          this.roleForm.reset();
        });
      }
    }
  }



  editRole(roleId: number) {
    console.log('Edited Role:::', roleId);
    for (const role of this.roles) {
      if (role.roleId === roleId) {
        this.roleForm.get('roleId').setValue(role.roleId);
        this.roleForm.get('roleName').setValue(role.roleName);
        //this.roleForm.get('status').setValue(role.status);
        this.roleForm.get('roleDesc').setValue(role.roleDesc);
        break;
      }
    }
  }
  resetRoles() {
    this.roleForm.reset();
  }
  updateRole(roleId: number) {
    this.roleService.updateRole(roleId).subscribe((response: any) => {
      //alert(response.message);
      this.messageService.add({ severity: 'success', summary: 'Role Status Changed Successfully.', detail: '', sticky: false });
      this.getAllRoles();
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}
