import { Component, OnInit, ViewChildren } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Organization } from '../models/organization.model';

@Component({
  selector: 'app-manage-organization',
  templateUrl: './manage-organization.component.html',
  styleUrls: ['./manage-organization.component.css']
})
export class ManageOrganizationComponent implements OnInit {

  @ViewChildren(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject();
  organizations: Array<Organization>;
  organization: Organization;
  display = false;

  constructor(private organizationService: OrganizationService) { }

  ngOnInit() {
    this.getAllOrganizationDetails();
  }

  getAllOrganizationDetails() {
    this.organizationService.getOrganizations().subscribe((response: Array<Organization>) => {
      this.organizations = response;
    });
  }

  editOrganization(orgId: number) {
    for (const org of this.organizations) {
      if (org.orgId === orgId) {
        this.organization = org;
      }
    }
    this.display = true;
  }

}
