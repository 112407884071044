import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MdocsPptService } from '../services/mdocsppt.services';
import { MdocsPptmodel } from '../models/mdocsppt.models';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../auth.service';
import { environment } from '../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ppts',
  templateUrl: './ppts.component.html',
  styleUrls: ['./ppts.component.css']
})
export class PptsComponent implements OnInit {

  mdocsForm: FormGroup;
  @Input() disabled = false;
  submitted = false; requiredmsg = false;
  selectedtId = 0; status = false;
  selectedDocument: File; documentUpload = "";
  currentDate = new Date();
  mdoclst: MdocsPptmodel[] = []; unitId = 0; envurl = environment.waterMeter; closeResult: string;docDescription="";mdocPPt=new MdocsPptmodel();
  constructor(private fb: FormBuilder, private router: Router, private messageService: MessageService, private modalService: NgbModal, private authService: AuthService, private mdocsService: MdocsPptService) {
    this.unitId = this.authService.getUser().unitId;
  } 

  ngOnInit() {
    this.createForm();
    const startDateIn = new Date();
    //   this.mdocsForm.get('FromDate').setValue(startDateIn);
    // this.retrieveunitdetails();
    this.getList();
    this.mdocsService.MdocsPptActionObservable.subscribe((consuptId: number) => {
      if (consuptId) {
        this.selectedtId = consuptId;
        // this.mdocsService.ge(consuptId).subscribe((effmodel: Consumptionmodel) => {
        //   console.log(effmodel);
        //   this.populateroadTaxtDetais(effmodel);

        // });
      }
      this.requiredmsg = false;
    });
  }
  createForm() {
    this.mdocsForm = this.fb.group({
      //vendorId: ['', Validators.required],
      Title: ['', Validators.required],
      Description: ['', Validators.required],

    });

    if (this.mdocsForm.valid) {
      this.disabled = true;
    }
  }
  onSelectedDocChange(event) {
    if (event.target.files.length > 0) {
      this.selectedDocument = event.target.files[0];
    }
  }
  formreset() {
    this.mdocsForm.reset();
    //this.mdocsForm.get("UnitID").setValue('');
  }
  // retrieveunitdetails() {
  //   this.unitservice.getallUnitWaterList().subscribe((resp: any) => {
  //     if (resp) {
  //       this.unitwaterlst = resp.UnitMasterList;
  //     }
  //   });
  // }
  // populateroadTaxtDetais(getDetails: Consumptionmodel) {
  //   //this.roadtaxForm.get("vendorId").setValue(getDetails.vendorId);
  //   this.mdocsForm.get("UnitID").setValue(getDetails.UnitID);
  //   //const roadTaxValidity = new Date(getDetails.roadTaxValidity);
  //   this.mdocsForm.get("FromDate").setValue(this.formatDate(getDetails.FromDate));
  //   this.mdocsForm.get("Addition").setValue(getDetails.Addition + "");
  //   this.mdocsForm.get("Reduction").setValue(getDetails.Reduction + "");
  //   this.mdocsForm.get("Reamrks").setValue(getDetails.Reamrks + "");
  //   this.selectedtId = getDetails.ID;
  //   // this.status = getDetails.Status;

  // }

  registerMdoc() {
    if (this.mdocsForm.valid) {
      this.requiredmsg = false;
      const MdocData = this.mdocsForm.value;
      MdocData.PptId = this.selectedtId;
      MdocData.UnitID = this.authService.getUser().unitId;
      MdocData.Status = false;
      // EffData.Status = this.status;
      const uploadData = new FormData();
      //  uploadImageData.append('pPT', MdocData)
      uploadData.append('file', this.selectedDocument);
      uploadData.append('mdocdata', JSON.stringify(MdocData));
      this.mdocsService.addMdocsPpt(uploadData).subscribe((response: any) => {
        console.log(response);
        var statusval = this.selectedtId == 0 ? "added" : "update";
        this.messageService.add({ severity: 'success', summary: 'Mdocs PPT ' + statusval + ' successfully', detail: '', sticky: false });
        this.selectedtId = 0;
        this.formreset();
        this.status = false;this.getList();
        this.mdocsService.notifyMdocsPptreated();
      });
    }


  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getList() {
    this.mdocsService.GetMdocsPptList(this.unitId, null).subscribe((resp: any) => {
      console.log(resp.MDocPptDetails);
      this.mdoclst = resp.MDocPptDetails;
    });
  }
  updateStatus(doc: MdocsPptmodel) {
    this.mdocsService.UpdateStatus(doc.PptId, doc.Status).subscribe((resp: any) => {
      var statusval=doc.Status==true?"Active":"InActive";
      this.messageService.add({ severity: 'success', summary: 'Status '+statusval+' successfully', detail: '', sticky: false });
    });
  }
  openViewForm(content, docModel: MdocsPptmodel) {
    this.mdocPPt=docModel;
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}