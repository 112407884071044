import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from './models/role.model';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  //private baseUrl = environment.adminApiUrl + 'api/admin/role';
  public apiUrl = environment.adminApiUrl + 'biac/admin/common';
  private baseUrl = environment.adminApiUrl +"api/Roll";
  constructor(private httpClient: HttpClient) {}

  addRole(role: any) {
    return this.httpClient.post(this.baseUrl+"/Save", role);
  }
  retrieveRole() {
    return this.httpClient.get(this.baseUrl+"/GetRollsList");
  }
  retrieveAllRoles() {
    return this.httpClient.get(this.baseUrl+"/GetRollsList");
  }

  editRole(role: Role) {
    return this.httpClient.put(this.baseUrl, role);
  }
  updateRole(roleId: number ) {
    return this.httpClient.delete(this.baseUrl + '/RollChangeStatus?rollid=' + roleId);
  }


}
