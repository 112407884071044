import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { EmployeeService } from '../../employee.service';
import { Address } from '../../models/address.model';
import { Employee } from '../../models/employee.model';
import { ExternalEmployeeComponent } from '../external-employee/external-emp.component';
@Component({
  selector: 'app-emp-address',
  templateUrl: './emp-address.component.html',
  styleUrls: ['./emp-address.component.css']
})
export class EmpAddressComponent implements OnInit {

  empAddressForm: FormGroup;
    address: Address;
    constructor(private fb: FormBuilder, private employeeService: EmployeeService, private EmpComponet: ExternalEmployeeComponent) { }
  @Output() addressData = new EventEmitter();
    selectedEmpID: number; requiredmsg = false;
    isEdit = false;
    savebutton = false;
    addAddressValues() { // You can give any function name
        if (this.empAddressForm.valid) {
            this.requiredmsg = false;
            this.address = this.empAddressForm.value;
            this.addressData.emit(this.address);
           // console.log(this.address);
        } else {
            this.requiredmsg = true;
        }
  }
  ngOnInit() {
    this.createAddressForm();

    this.employeeService.employeeObservable.subscribe((isClicked: boolean) => {
      if (isClicked) {
        this.addAddressValues();
      }
    });

    this.employeeService.employeeCreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.empAddressForm.reset();
      }
    });
    this.employeeService.employeeEditObservable.subscribe((employeeId: number) => {
      if (employeeId) { 
        this.selectedEmpID = employeeId;       
        this.employeeService.getEmployeeForEdit(employeeId).subscribe((empGenralDetails: Employee) => {
          this.populateEmpAddressDetails(empGenralDetails);
        });
      }
        if (employeeId) {
            this.isEdit = true;
        } else {
            this.isEdit = false;
        }
    });
  }
  createAddressForm() {
    this.empAddressForm = this.fb.group({
      addressLine1: ['', Validators.required],
      // addressLine2: ['', Validators.required],
      city: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      state: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      country: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      zipCode: ['', [Validators.required, Validators.pattern('[1-9][0-9]{5}') ]]
  });
   }
   populateEmpAddressDetails(empGenralDetails: Employee) {
    console.log("EmpEditorDetails",empGenralDetails);
    this.empAddressForm.get('addressLine1').setValue(empGenralDetails.addressLine1);
    this.empAddressForm.get('city').setValue(empGenralDetails.city);
    this.empAddressForm.get('state').setValue(empGenralDetails.state);
    this.empAddressForm.get('country').setValue(empGenralDetails.country);
    this.empAddressForm.get('zipCode').setValue(empGenralDetails.zipCode);
           
   }
    EmpAddressFormreset() {
        this.empAddressForm.reset();

    }
    backgeneral() {
        this.EmpComponet.index = 0;
    }


}
