import { Component, ViewChild, OnInit, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ParameterService } from '../parameter.service';
import { Parameter } from '../models/parameter.model';
import { ConfigType } from '../models/configtype.model';
import { ConfirmationService } from 'primeng/api';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-masterdatamanagement',
  templateUrl: './masterdatamanagement.component.html',
  styleUrls: ['./masterdatamanagement.component.css']
})
export class MasterdatamanagementComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  parameterForm: FormGroup;
  configForm: FormGroup;
  parameters: Array<Parameter>;

  configTypes: Array<ConfigType>;
  showConfigForm: boolean;
  cStatus: boolean;
  configParam: Parameter;
  booleanString = true;
  constructor(private fb: FormBuilder,private messageService: MessageService,  private parameterService: ParameterService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.parameters = [];
    this.configTypes = [];
    this.showConfigForm = false;
    this.initializeForm();
    this.initializeConfigForm();
    this.cStatus = true;
    this.getConfigTypes();
    this.getAllParametersData();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Master Data Management',
          filename: 'masterdatamangement-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0, 1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Master Data Management',
          filename: 'masterdatamangement-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0, 1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  initializeForm() {

    this.parameterForm = this.fb.group({
      configType: ['', Validators.required]
    });
  }

  initializeConfigForm() {

    this.configForm = this.fb.group({
      configId: [''],
      configVal: ['', Validators.required],
      configVal2: [''],
      configVal3: [''],
      configType: this.fb.group({
        configTypeId: ['', Validators.required]
      }),
      status: [''],
      isParent: [''],
      configValDesc: ['']
    });
  }

  getConfigTypes() {
    this.parameterService.getConfigTypes().subscribe((response: any) => {
      this.configTypes = response.Configmaster;
      console.log(response);
    });
  }

  openConfigForm() {
    if (this.parameterForm.value.configType !== '') {
      console.log('Config Type ::: ', this.parameterForm.value);
      this.configForm.get(['configType', 'configTypeId']).
        setValue(this.parameterForm.get('configType').value);
      this.showConfigForm = true;
    } else {
      alert('Please select AssetType');
    }
  }

  addConfig() {
    this.configParam = this.configForm.value;
    if (!this.configParam.configId) {
      this.configParam.configId = 0;
    }
    const configType = this.getConfigType(this.configParam.configType.configTypeId);
    console.log('Config Type:::', configType);
    this.configParam.isParent = this.configParam.isParent ? 'Y' : 'N';
    this.configParam.isParentModuleID = this.configParam.isParent == "Y" ? parseInt(configType.configTypeId) : parseInt(configType.configTypeId);
    this.configParam.configType = configType;
    this.configParam.configValDesc = this.configParam.configValDesc;
    console.log(this.configParam.configType);
    this.parameterService.addConfigParam(this.configParam).subscribe((response: any) => {
      if (response) {
        alert('Parameter Added successfully...');
        this.showConfigForm = false;
        this.configForm.reset();
        this.getAllParametersData();
        this.getConfigTypes();
      }
      console.log(response);
    });
  }
  getConfigType(configTypeId: string) {
    for (const configType of this.configTypes) {

      if (configType.configTypeId == configTypeId) {

        return configType;
      }
    }
    return null;
  }
  getConfigofConfig(configTypeId: string) {
    for (const configType of this.configTypes) {
      if (configType.configTypeId == configTypeId) {

        return configType;
      }
    }
  }

  getAllParametersData() {
    this.parameterService.retrieveAllParametersData().subscribe((response: any) => {
      this.parameters = response.Configlst; console.log(response);
      this.rerender();
    });
  }

  editConfigParam(configId: number) {
    const configParam = this.getParameter(configId);
    this.configForm.get('configVal').setValue(configParam.configVal);
    this.configForm.get('configVal2').setValue(configParam.configVal2);
    this.configForm.get('configVal3').setValue(configParam.configVal3);
    this.configForm.get('configValDesc').setValue(configParam.configValDesc);
    this.configForm.get(['configType', 'configTypeId']).setValue((configParam.configType) ? configParam.configType.configTypeId : null);
    this.configForm.get('status').setValue(configParam.status);
    this.configForm.get('configId').setValue(configParam.configId);
    this.configForm.get('isParent').setValue((configParam.isParent == 'Y') ? true : false);

    this.showConfigForm = true;
  }

  getParameter(configId: number) {
    for (const configParam of this.parameters) {
      if (configParam.configId === configId) {
        return configParam;
      }
    }
    return null;
  }

  deleteConfigParam(configId: number, status: boolean) {
    console.log(status);
    //    if(status==='Y') 
    //    {
    // this.cStatus=
    //    }
    //    else{
    //     this.cStatus=='Y';
    //    }
    this.confirmationService.confirm({
      message: 'Are you sure that you want to change the status?',
      accept: () => {
        this.parameterService.statusUpdationConfigParam(configId, status).subscribe((response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Config Param update successfully.', sticky: false });
          //alert('Config Param update successfully..!');
          this.getAllParametersData();

        });
      }
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}
