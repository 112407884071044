import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public apiUrl = environment.adminApiUrl + 'biac/admin/common';

  constructor(private httpClient: HttpClient) { }

  getActivities() {
    return this.httpClient.get(this.apiUrl + '/activity');
  }
}
