import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnitWatermeterComponent } from './unit-watermeter/unit-watermeter.component';
import { EffluentWatermeterComponent } from './effluent-watermeter/effluent-watermeter.component';
import { FormUnitComponent } from './unit-watermeter/form-unit/form-unit.component';
import { ListUnitComponent } from './unit-watermeter/list-unit/list-unit.component';
import { FormEffluentComponent } from './effluent-watermeter/form-effluent/form-effluent.component';
import { ListEffluentComponent } from './effluent-watermeter/list-effluent/list-effluent.component';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import { DataTablesModule } from 'angular-datatables';
import { ConsumptionmodificationComponent } from './consumptionmodification/consumptionmodification.component';
import { AddconsumptionComponent } from './addconsumption/addconsumption.component';
import { MonthwisereportComponent } from './monthwisereport/monthwisereport.component';
import { MeterwisegraphComponent } from './meterwisegraph/meterwisegraph.component';
import { WaterreportComponent } from './waterreport/waterreport.component';
import { WaterreportapprovalComponent } from './waterreportapproval/waterreportapproval.component';
import { WaterreportbyunitComponent } from './waterreportbyunit/waterreportbyunit.component';
import { WaterreportapprovalbyunitComponent } from './waterreportapprovalbyunit/waterreportapprovalbyunit.component';
import {RouterModule} from '@angular/router';
@NgModule({
  declarations: [UnitWatermeterComponent, EffluentWatermeterComponent, FormUnitComponent, ListUnitComponent, FormEffluentComponent, ListEffluentComponent, ConsumptionmodificationComponent, AddconsumptionComponent, MonthwisereportComponent, MeterwisegraphComponent, WaterreportComponent, WaterreportapprovalComponent, WaterreportbyunitComponent, WaterreportapprovalbyunitComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    ToastModule,
    MessageModule,
    TabViewModule,
    InputSwitchModule,
    CheckboxModule,
    DialogModule,
    ReactiveFormsModule,
    DataTablesModule,
    ConfirmDialogModule,
    ToastModule,RouterModule,
  ]
})
export class WatermeterModule { }
