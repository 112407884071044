import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EffluentwatermeterModel } from '../modles/effluentwatermeter.model';
@Injectable({
    providedIn: 'root'
})
export class EffluentwatermeterService {

    private baseUrl = environment.waterMeter + 'api/Unit/';
    constructor(private httpClient: HttpClient) { }

    private EffluentwatermeterreatedSubject = new Subject<any>();
    EffluentwatermeterreatedObservable = this.EffluentwatermeterreatedSubject.asObservable();

    private EffluentwatermeterEditSubject = new Subject<any>();
    EffluentwatermeterActionObservable = this.EffluentwatermeterEditSubject.asObservable();

    addEffluentwatermeter(Effluentwatermeter: EffluentwatermeterModel) {
        return this.httpClient.post(this.baseUrl+'SaveEffluent', Effluentwatermeter);
    }
    getallEffluentwatermeterList() {
        return this.httpClient.get(this.baseUrl + "GetEffulentList");
    }
    GetEffluent(EffluentID:number) {
        return this.httpClient.get(this.baseUrl + "GetEffluent?EffluentID="+EffluentID);
    }
    

    DeleteEffluent(EffulentID:number){
        return this.httpClient.post(this.baseUrl+'/DeleteUnit?UnitID='+EffulentID, {});
    }

    getallEffluentwatermeterbyunitId(unitID:number) {
        return this.httpClient.get(this.baseUrl + "/unitId?unitID="+unitID);
    }
    getallNamesEffluentwatermeter() {
        return this.httpClient.get(this.baseUrl + "/allNames");
    }
    getEffluentwatermeter(superID: number) {
        return this.httpClient.get(this.baseUrl + '/' + superID);
    }
    getEffluentwatermeterByRegId(RegId: number) {
        return this.httpClient.get(this.baseUrl + '/id?vehicleRegId=' + RegId);
    }
    getEffluentwatermeterlistall(){
        return this.httpClient.get(this.baseUrl + "/allNames");
    }
    getEffluentwatermeterbyUnitVendorID(unitID:number,firmID:number){
        return this.httpClient.get(this.baseUrl + "/getByUnitContractorId?unitID="+unitID+"&contractorId="+firmID);
    }
    getByVendorId(firmId:number){
        return this.httpClient.get(this.baseUrl + "/getByContractorId?contractorId="+firmId);
    }

    notifyEffluentwatermeterreated() {
        this.EffluentwatermeterreatedSubject.next(true);
    }
    editEffluentwatermeterRequest(superID: number) {
        this.EffluentwatermeterEditSubject.next(superID);
    }
    updateStatus(superID:number,status:string){
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID+'&status='+status);

    }
    uploadDocument(fromdata: FormData){
        return this.httpClient.post(this.baseUrl + '/uploadPhoto', fromdata, { observe: 'response' });
    }
}
