import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Consumptionmodel, UnitMasterListModel } from '../modles/consumptions.model';
@Injectable({
    providedIn: 'root'
})
export class ConsumptionService {

    private baseUrl = environment.waterMeter;
    constructor(private httpClient: HttpClient) { }

    private ConsumptionCreatedSubject = new Subject<any>();
    ConsumptionreatedObservable = this.ConsumptionCreatedSubject.asObservable();

    private ConsumptionEditSubject = new Subject<any>();
    ConsumptionActionObservable = this.ConsumptionEditSubject.asObservable();

    addConsumption(Consumption: Consumptionmodel) {
        return this.httpClient.post(this.baseUrl + 'api/Unit/SaveConsumption', Consumption);
    }
    GetConsumptionList(FromDate: string, UnitID: number) {
        return this.httpClient.get(this.baseUrl + "api/Consumption/GetConsumptionList?FromDate=" + FromDate + "&UnitID=" + UnitID);
    }
    GetUnitmasterlist() {
        return this.httpClient.get(this.baseUrl + "api/Consumption/GetUnitmasterlist");
    }
    GetConsumptionDetail(UnitID:number) {
        return this.httpClient.get(this.baseUrl + "api/Consumption/GetConsumptionDetail?UnitID="+UnitID);
    }
    Getmonthwisereport(FromDate: string, Todate: string, UnitID: number){
        return this.httpClient.get(this.baseUrl + "api/Consumption/Getmonthwisereport?FromDate=" + FromDate + "&Todate=" + Todate + "&UnitID=" + UnitID);
    }
    Getdashboardmonthwisereport(FromDate: string, Todate: string, UnitID: number){
        return this.httpClient.get(this.baseUrl + "api/Consumption/Getdashboardmonthwisereport?FromDate=" + FromDate + "&Todate=" + Todate + "&UnitID=" + UnitID);
    }
    Getdashboardyearwisereport(Year: number, Month: number, UnitID: number){
        return this.httpClient.get(this.baseUrl + "api/Consumption/Getdashboardyearwisereport?Year=" + Year + "&Month=" + Month + "&UnitID=" + UnitID);
    }

    notifyConsumptionreated() {
        this.ConsumptionCreatedSubject.next(true);
    }
    editConsumptionRequest(superID: number) {
        this.ConsumptionEditSubject.next(superID);
    }
    updateStatus(superID: number, status: string) {
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID + '&status=' + status);

    }
    uploadDocument(fromdata: FormData) {
        return this.httpClient.post(this.baseUrl + '/uploadPhoto', fromdata, { observe: 'response' });
    }
}
