export class Privilege {
    accessId: number;
    roleId: number;
    moduleId: number;
    subModuleId: number;
    activityId: number;
    status: string;
    access: boolean;
    moduleName: string;
    subModuleName: string;
    activityName: string;
  }
  export class ConfigModel{
    configId:number;
    configVal:string;
    configVal2:string;
    configVal3:string;
    configValDesc:string;
    isParentModuleID:number
    status:boolean;
    configType:configTypeModel;
  }
  export class configTypeModel{
    configTypeId:number;
    configTypeName:string;
    configValDesc:string;
    is_paretnTypeID:string;
    status:boolean
  }
