import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WaterReportmeterModel } from '../modles/waterreport.model';
import { WaterReportService } from '../services/watermeter.services';
import { MessageService } from 'primeng/api';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-waterreportbyunit',
  templateUrl: './waterreportbyunit.component.html',
  styleUrls: ['./waterreportbyunit.component.css']
})
export class WaterreportbyunitComponent implements OnInit {
  dateval = new Date();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  closeResult: string;
  watermeterlst: Array<WaterReportmeterModel> = [];
  waterdata = new WaterReportmeterModel();
  minimumDate=new Date();unitId = 0; 
  constructor(private authService: AuthService, private waterService: WaterReportService, private messageService: MessageService, private modalService: NgbModal) {
    this.unitId = this.authService.getUser().unitId;
   }

  ngOnInit() {
    this.waterReportList();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Water Report Unit',
          filename: 'Water Report Unit-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0, 1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Water Report',
          filename: 'Water Report Unit-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0, 1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };

    

  }
  waterReportList(){
    var  setdate=this.SetformatDate(this.dateval);
    this.waterService.getallWaterReportbyUniId(setdate,this.unitId).subscribe((response: any) => {
      this.watermeterlst = response.mwatermeterlst;
      console.log(this.watermeterlst);
      this.rerender();
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  SetformatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    return [day, month, year].join('/');
  }
  openViewForm(content, watermeterdata: WaterReportmeterModel) {

    // console.log(this.employees);
    //this.empViewForm = true;
    if (watermeterdata.ID == 0) {
      this.waterdata = new WaterReportmeterModel();
      this.waterdata.ID = 0;
      this.waterdata.Datetime = this.dateval;
      this.waterdata.UnitName=watermeterdata.UnitName;
      this.waterdata.DeviceID=watermeterdata.DeviceID;
    } else {
      this.waterdata = watermeterdata;
    }
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); 

  }
  SaveWater(){
    this.waterService.addWaterReport(this.waterdata).subscribe((response: any) => {
      this.modalService.dismissAll();
      this.messageService.add({ severity: 'success', summary: 'Water Data Save successfully', detail: '', sticky: false });
      this.waterReportList();
    });

  }
  ShowRejectPopup(content, watermeterdata: WaterReportmeterModel){
    this.waterdata = watermeterdata;
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); 
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}

