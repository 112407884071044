import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Event, RoutesRecognized } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { PrivilegesService } from './privileges.service';
import { Menu } from './admin/models/menu.model';
import { AuthService } from './auth.service';
import { Employee } from './admin/models/employee.model';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Brandix';
    url = ''; elem: any;

    menu: Array<Menu> = [];
    roleId: number;
    username = '';
    todayDate: Date = new Date();
    time = new Date();
    timer; pagePath = "";
    constructor(private location: Location, private router: Router, private privilegesService: PrivilegesService,
        private authService: AuthService, private bnIdle: BnNgIdleService, @Inject(DOCUMENT) private document: any) {
        //method with 300 seconds (5 minutes) 
        this.bnIdle.startWatching(300).subscribe((res) => {
            if (res) {
                console.log("session expired");
                this.logout();
            }
        })

        router.events.subscribe((event: Event) => {
            if (event instanceof RoutesRecognized) {
                if (location.path() !== '' && location.path() !== "/resetPassword") {
                    if (!authService.isUserExist()) {
                        router.navigate(['login']);
                    }
                }
            }
        });
        if (location.path() !== '' && location.path() !== "/resetPassword") {
            if (!authService.isUserExist()) {
                router.navigate(['login']);
            }
        }
        this.pagePath = location.path();
    }


    ngOnInit() {
        this.elem = document.documentElement;
        this.authService.roleIdObservable.subscribe((roleId: number) => {
            this.roleId = roleId;
            this.getDynamicMenu();
            var middleName = this.authService.getUser().middleName != null && this.authService.getUser().middleName != "" ? " " + this.authService.getUser().middleName : "";
            this.username = this.authService.getUser().firstName + middleName + " " + this.authService.getUser().lastName;
        });

        if (this.authService.isUserExist()) {
            this.roleId = this.authService.getUser().roleId;
            this.getDynamicMenu();
            var middleName = this.authService.getUser().middleName != null && this.authService.getUser().middleName != "" ? " " + this.authService.getUser().middleName : "";
            this.username = this.authService.getUser().firstName + middleName + " " + this.authService.getUser().lastName;
            if (this.location.path() == '' || this.location.path() == '/login') {
                this.router.navigate(['welcome']);
            }

        }
        this.timer = setInterval(() => {
            this.time = new Date();
        }, 1000);
    }
    ngOnDestroy() {
        clearInterval(this.timer);
    }
    isUserLoggedIn() {
        
        return this.authService.checkLoginStatus();
    }
    openFullscreen() {
        if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
          /* Firefox */
          this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
          /* IE/Edge */
          this.elem.msRequestFullscreen();
        }
      }

    getDynamicMenu() {
        console.log('Role Id::::', this.roleId); this.menu = [];
        this.privilegesService.getDynamicMenu(this.roleId).subscribe((result: Array<Menu>) => {
        console.log(result);
            result.forEach(item => {
                if (item.menuId != 35 && item.menuId != 36 && item.menuId != 37) {
                    this.menu.push(item);
                }
            });
            // console.log(this.menu);
            //   this.menu = result;
        });
    }

    logout() {
        // //event.preventyDefault(); 
        // sessionStorage.setItem('user',null);
        // //sessionStorage.setItem("Loginstatus","0");
        // this.isUserLoggedIn();
        // //sessionStorage.clear();
        // //this.authService.logout().subscribe()
        // //alert('User logged out successfully...! ');
        // //window.location.href = 'login';
        // //this.authService.behaviour.next("logout");
        // this.router.navigate(['login']);
        this.authService.logout();
        //alert('User logged out successfully...! ');
        // alert(window.location.protocol+"//"+window.location.hostname);
        var currentpath = window.location.href.split('/#/');
        window.location.href = currentpath[0];

        sessionStorage.clear();
    }
    Logoicon(menuname: string) {
        if (menuname.toLocaleLowerCase() == "admin") {
            return "../assets/images/user.svg";
        } else if  (menuname.toLocaleLowerCase() === "wms" || menuname.toLocaleLowerCase() === "wm") {
            return "../assets/images/water-drop.png";
        }
        else if  (menuname.toLocaleLowerCase() === "mdocs" || menuname.toLocaleLowerCase() === "mdocs") {
            return "../assets/images/document.png";
        }
        else{
            return "../assets/images/user.svg";
        }
        
    }
}

