import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Parameter } from './models/parameter.model';

import { environment } from '../../environments/environment';


const configTypeUrl = environment.adminApiUrl + 'api/admin/configType';
const configUrl = environment.adminApiUrl + 'api/admin/config';

const StatusUpdateUrl = environment.adminApiUrl + 'api/admin/configStatusUpdation';

const configParamUrl = environment.adminApiUrl + "api/Config"

const configTypeUrlMater = environment.adminApiUrl;
@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(private httpClient: HttpClient) { }

  retrieveAllParametersData() {
    return this.httpClient.get(configTypeUrlMater + "api/MasterDataConfig/GetMasterDataConfig");
  }


  getConfigTypes() {
    return this.httpClient.get(configTypeUrlMater + "api/Config/GetConfigName");
  }

  addConfigParam(configParam: Parameter) {
    return this.httpClient.post(configTypeUrlMater + "api/MasterDataConfig/Save", configParam);
  }

  // deleteConfigParam(configId: number) {
  //   return this.httpClient.delete(configUrl + '/' + configId);
  // }
  statusUpdationConfigParam(configId: number, status: Boolean) {
    return this.httpClient.get(configTypeUrlMater + 'api/MasterDataConfig/StatusUpdate?configId=' + configId + '&status=' + status);


  }
  getDepartments() {
    return this.httpClient.get(configParamUrl + '/GetConfigParameters?ParameterType=Department');
  }

  getDesignations() {
    return this.httpClient.get(configParamUrl + '/GetConfigParameters?ParameterType=Designation');
  }

  getModules() {
    console.log('Modules...');
    return this.httpClient.get(environment.adminApiUrl + '/api/Menu/GetModules');
  }

  getConfigParams(configTypeId: number, roleID: number) {
    return this.httpClient.get(environment.adminApiUrl + '/api/admin/config/configId?ConfigTypeId=' + configTypeId + "&roleID=" + roleID);
  }


  getSubModules(moduleId: number, RoleID: number) {
    return this.httpClient.get(environment.adminApiUrl + '/api/Menu/GetSubmodules?ModuleID=' + moduleId + "&RoleID=" + RoleID);
  }
}
