export class  Address {
	addressId: number;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	country: string;
	zipCode: string;
	isPrimary: string;
	addrType: string;
}
