import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Organization } from '../models/organization.model';
import { OrganizationService } from '../organization.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { Parameter } from '../models/parameter.model';
@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.css']
})
export class OrgListComponent implements OnInit, OnDestroy, AfterViewInit {

  showViewForm: boolean;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  closeResult: string;
  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  orgList: Array<Organization>;
  orgFormDetails = [];
  organizations: Array<Organization>;
  organization: Organization;
  display = false;
  departments: Array<Parameter> = [];
  msgs: Message[] = [];


  // tslint:disable-next-line:max-line-length
  constructor(private orgService: OrganizationService, private messageService: MessageService, private modalService: NgbModal, private organizationService: OrganizationService, private confirmationService: ConfirmationService,private parameterService: ParameterService) {

    this.orgService.orgCreatedObservable.subscribe((res: boolean) => {
      if (res) {
        this.getOrganizations();
      }
    });
  }

  ngOnInit() {
    this.orgList = [];
    this.orgFormDetails = [];
    this.getOrganizations();
    this.organizations = [];
   // this.getAllOrganizationDetails();
    this.getDepartments();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Organization',
          filename: 'Organization-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8,9,10]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Organization',
          filename: 'Organization-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12,13,14,15]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getOrganizations() {
    this.orgService.getOrganizations().subscribe((response: any) => {
      if (response) {
        console.log(response);
        this.orgList = response.MOrganiserDetails;
      } else {
        this.orgList = [];
      }
      this.rerender();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }


  openViewForm(content, orgId: number) {

    this.showViewForm = true;
    this.orgService.getOrganization(orgId).subscribe((org: Organization) => {
      if (org) {
        this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;

        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      this.orgFormDetails = [{
        orgName: org.orgName,
        orgDescription: org.orgDescription,
        orgCode: org.orgCode,
        orgWebsite: org.orgWebsite,
        orgPhoneNo: org.orgPhoneNo,
        addressLine1: org.addressLine1,
        // addressLine2 : org.address.addressLine2,
        city: org.city,
        state: org.state,
        country: org.country,
        zipCode: org.zipCode,
        contactPersonName: org.contactPersonName,
        department: org.department,
        email: org.email,
        work: org.work,
        mobile: org.mobile,
      }];
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
  getAllOrganizationDetails() {
    this.organizationService.getOrganizations().subscribe((response: Array<Organization>) => {
      this.organizations = response;
    });
  }
  editOrganization(orgId: number) {
    this.organizationService.editOrginization(orgId);
    // for (const org of this.organizations) {
    //   if (org.orgId === orgId) {
    //     this.organization = org;
    //   }
    // }
    // this.display = true;
  }

  deleteConfigParam(orgId: number, orgStatus: boolean, orgName: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to change the status?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.orgService.statusUpdationOrganization(orgId).subscribe((response: any) => {
          // this.msgs = [{severity:'info', summary:'Confirmed', detail:'Config Param deleted successfully..!'}];
          this.messageService.add({ severity: 'success', summary: 'Organiser Status Changed Successfully.', sticky: false });
        });
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  closeViewForm() {
    this.showViewForm = false;
  }
  getDepartments() {
    this.parameterService.getDepartments().subscribe((response: any) => {
      this.departments = response.Configlst;
    });
  }
  getdepartmentName(dptId: number) {
    for (let dpt in this.departments) {
      if (dptId == this.departments[dpt].configId) {
        return this.departments[dpt].configVal;
      }
    }
    return "";
  }
}
