import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdocsMoviesService } from '../../services/mdocsmovies.services';
import { MdocsMoviesmodel } from '../../models/mdocsmovies.models';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../auth.service';
import { environment } from '../../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-movies-list',
  templateUrl: './movies-list.component.html',
  styleUrls: ['./movies-list.component.css']
})
export class MoviesListComponent implements OnInit {

  mdoclst: MdocsMoviesmodel[] = []; unitId = 0; envurl = environment.waterMeter;
  closeResult: string;docDescription="";mdocMovie=new MdocsMoviesmodel();
  constructor(private router: Router, private messageService: MessageService,private modalService: NgbModal, private authService: AuthService, private mdocsService: MdocsMoviesService) {
    this.unitId = this.authService.getUser().unitId; 
  }
  ngOnInit() {
    this.getList();
    this.mdocsService.MdocsMoviesreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.getList();
      }
    });
  }
  getList() {
    this.mdocsService.GetMdocsMoviesList(this.unitId,true).subscribe((resp: any) => {
      console.log(resp.MDocMovieDetails);
      this.mdoclst = resp.MDocMovieDetails;
    });
  }
  gotoUpload(){
    this.router.navigate(['moviesupload']);
  }

  openViewForm(content, docModel: MdocsMoviesmodel) {
    this.docDescription = docModel.Description;
    this.mdocMovie=docModel;
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}

