import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { ParameterService } from '../../parameter.service';
import { Parameter } from '../../models/parameter.model';
import { OrganizationService } from '../../organization.service';
import { Organization } from '../../models/organization.model';
import { Contact } from '../../models/contact.model';
import { OrganizationComponent } from '../organization.component';

@Component({
  selector: 'app-org-contact',
  templateUrl: './org-contact.component.html',
  styleUrls: ['./org-contact.component.css']
})
export class OrgContactComponent implements OnInit, OnChanges {

  @Input() contactDetails: Contact;

  orgContactForm: FormGroup;
  contactDataArray: Array<Contact>;
  departments: Array<Parameter>;
    selectedOrgId: number; requiredmsg = false;
  isEdit = false;

    constructor(private fb: FormBuilder, private parameterService: ParameterService, private orgService: OrganizationService, private OrgComponent: OrganizationComponent) { }
  @Output() contactData = new EventEmitter();

    addedContactValues() { // You can give any function name
        if (this.orgContactForm.valid) {
            this.requiredmsg = false;
            this.contactDataArray = this.orgContactForm.value;
            this.contactData.emit(this.contactDataArray);
            console.log(this.contactDataArray);
        } else {
            this.requiredmsg = true;
        }
  }

  ngOnInit() {
    this.createContactForm();
    this.getDepartments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const contact: Contact = changes.contactDetails.currentValue;
    if (contact && contact.contactId) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    if (contact) {
      this.populateContactDetails(contact);
    } else {
      if (this.orgContactForm) {
          this.ContactFormreset();
      }
    }
  }

  populateContactDetails(contact: Contact) {
    console.log('populateContactDetails::' , contact);
    this.orgContactForm.get('contactPersonName').setValue(contact.contactPersonName);
    this.orgContactForm.get('department').setValue(contact.department);
    this.orgContactForm.get('email').setValue(contact.email);
    this.orgContactForm.get('orgOfficeNumber').setValue(contact.work);
    this.orgContactForm.get('mobile').setValue(contact.mobile);
}

  createContactForm() {
    this.orgContactForm = this.fb.group({
      contactPersonName: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      department: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      orgOfficeNumber: [''],
        mobile: ['', [Validators.required, Validators.pattern(/^(?!(\d)\1+$)(?:\(?\+\d{1,3}\)?[- ]?|0)?\d{10}$/)]],
      //fax: ['', Validators.required]
    });
   }

   getDepartments() {
    this.parameterService.getDepartments().subscribe((response: any) => {
      this.departments = response.Configlst;
    });
   }
    ContactFormreset() {
        this.orgContactForm.reset();
        this.orgContactForm.controls['department'].setValue('', { onlySelf: true });

    }
    backaddress() {
        this.OrgComponent.index = 1;
    }

}
