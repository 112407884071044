import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role/role.component';
import { OrganizationComponent } from './organization/organization.component';
import { OrgGeneralComponent } from './organization/org-general/org-general.component';
import { OrgAddressComponent } from './organization/org-address/org-address.component';
import { OrgContactComponent } from './organization/org-contact/org-contact.component';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { DataTablesModule } from 'angular-datatables';
import { DialogModule } from 'primeng/dialog';
import { ExternalEmployeeComponent } from './employees/external-employee/external-emp.component';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsComponent } from './employees/buttons/buttons.component';
import { EmpAddressComponent } from './employees/emp-address/emp-address.component';
import { EmpGeneralComponent } from './employees/emp-general/emp-general.component';
import {CalendarModule} from 'primeng/calendar';
// import { ParametermanagementComponent } from './parametermanagement/parametermanagement.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import { AssignPrivilegesComponent } from './assign-privileges/assign-privileges.component';
import { EmployeeListComponent } from './employees/employee-list/employee-list.component';
import { OrgListComponent } from './org-list/org-list.component';
import { NewOrgComponent } from './new-org/new-org.component';
import {ToastModule} from 'primeng/toast';
import { MasterdatamanagementComponent } from './masterdatamanagement/masterdatamanagement.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
      OrganizationComponent,
      OrgGeneralComponent,
      OrgAddressComponent,
      OrgContactComponent,
      ManageOrganizationComponent,
      ExternalEmployeeComponent,
      RoleComponent,
      ButtonsComponent,
      EmpAddressComponent,
      EmpGeneralComponent,
      // ParametermanagementComponent,
      AssignPrivilegesComponent,
      EmployeeListComponent,
      OrgListComponent,
      NewOrgComponent,
      MasterdatamanagementComponent
    ],
  imports: [
    FormsModule,
    CommonModule,
    TabViewModule,
    InputSwitchModule,
    CheckboxModule,
    DialogModule,
    ReactiveFormsModule,
    DataTablesModule,
    CalendarModule,
    ConfirmDialogModule,
    ToastModule,
    RouterModule,
    Ng4LoadingSpinnerModule.forRoot(),
  ],
  providers: [ConfirmationService, MessageService]
})
export class AdminModule { }
