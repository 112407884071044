import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { PrivilegesService } from 'src/app/privileges.service';
import { Privilege, ConfigModel } from '../models/privileges.model';
import { Role } from '../models/role.model';
import { RoleService } from '../role.service';
import { Activity } from '../models/activity.model';
import { CommonService } from 'src/app/common.service';
import { DataTableDirective } from 'angular-datatables';
import { ParameterService } from '../parameter.service';
import { Parameter } from '../models/parameter.model';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-assign-privileges',
  templateUrl: './assign-privileges.component.html',
  styleUrls: ['./assign-privileges.component.css']
})
export class AssignPrivilegesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  privileges: Array<Privilege> = [];
  activities: Array<Parameter> = [];

  accessPrivileges: Array<Privilege> = [];
  configModellst: Array<Parameter> = [];

  formPriviles = [];

  roles: Array<Role> = [];
  roleId = 0;
  moduleId = 0;
  modules: Array<any>;
  subModules: Array<Parameter> = [];
  access: Array<boolean> = [];

  constructor(private privilegeService: PrivilegesService, private roleService: RoleService, private commonService: CommonService,
    private parameterService: ParameterService, private messageService: MessageService) { }

  ngOnInit() {
    this.getModules();
    this.getRoles();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Access Privileges',
          filename: 'accessprivileges-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0,1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Access Privileges',
          filename: 'accessprivileges-' + this.formatDate(new Date()),
          exportOptions: {
            columns: [0,1, 2, 3, 4]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getSubModuleActivities() {
    this.getSubModules().then((result) => {
      this.rerender();
    }, (error) => {
      console.log(error);
    });
  }

  getSubModules() {
    return new Promise((resolve, reject) => {
      this.parameterService.getSubModules(this.moduleId, this.roleId).subscribe((response: any) => {
        this.subModules = response;
        let i = 0;
        console.log(response);
        for (const subModule of this.subModules) {
          console.log(subModule);
          this.getActivities(subModule).then((value) => {
            i = i + value;
            if (this.subModules.length === i) {
              this.populatePrivileges();
              resolve(true);
            }
          }, (error) => {
            console.log(error);
            reject(false);
          });
        }
      });

    });
  }


  onRoleSelect() {
    this.getPrivileges(this.roleId);
  }

  getActivities(subModule: Parameter): Promise<number> {
    // console.log(subModule);
    return new Promise((resolved, reject) => {
      this.accessPrivileges = [];
      this.parameterService.getConfigParams(subModule.configId, this.roleId).subscribe((response: any) => {
        this.activities = response;

        const accessPrivilege = new Privilege();
        accessPrivilege.moduleId = this.moduleId;
        accessPrivilege.moduleName = this.getModuleName(this.moduleId);
        accessPrivilege.subModuleId = subModule.configId;
        accessPrivilege.subModuleName = subModule.configVal;
        accessPrivilege.activityId = subModule.configId;
        accessPrivilege.activityName = subModule.configValDesc;
        accessPrivilege.access = Boolean(subModule.status);
        this.accessPrivileges.push(accessPrivilege);

        console.log(response);
        for (const activity of this.activities) {
          const accessPrivilege = new Privilege();
          accessPrivilege.moduleId = this.moduleId;
          accessPrivilege.moduleName = this.getModuleName(this.moduleId);
          accessPrivilege.subModuleId = activity.configId;
          accessPrivilege.subModuleName = activity.configVal;
          accessPrivilege.activityId = activity.configId;
          accessPrivilege.activityName = activity.configValDesc;
          accessPrivilege.access = Boolean(subModule.status);
          this.accessPrivileges.push(accessPrivilege);
        }
        resolved(1);
      }, (error) => {
        console.log(error);
        reject(0);
      });
    });

  }

  getPrivileges(roleId: number) {
    this.privilegeService.getAllPrivileges(roleId).subscribe((response: any) => {
      console.log(response);
      this.privileges = response.AccessprivilegeList;
      if (this.moduleId != undefined) {
        this.getSubModuleActivities();
      }
      //console.log(response);
      //this.populatePrivileges()
    });
  }

  // getRoles() {
  //   this.roleService.retrieveAllRoles().subscribe((response: [any]) => {
  //     if (response && response.length > 0) {
  //       this.roles = response;
  //     } else {
  //       this.roles = [];
  //     }
  //   });
  // }

  getRoles() {
    this.roles = [];
    this.roleService.retrieveAllRoles().subscribe((response: any) => {
      console.log(response);
      response.MRollsListDetails.forEach(item => {
        if (item.status === 'true') {
          this.roles.push(item);
        }
      });
      //this.rerender();
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  // getModules() {
  //   this.parameterService.getModules().subscribe((response: any) => {
  //     this.modules = response;
  //   });
  // }
  getModules() {
    this.parameterService.getModules().subscribe((response: any) => {
      console.log(response);
      this.modules = response;
      //this.modules = this.modules.filter(s => s.isParentModuleID == 3);
      // this.configModellst = response.Configlst;
      // console.log(response.Configlst);
    });
  }

  getModuleName(moduleId: number) {
    for (const module of this.modules) {
      if (module.configId == moduleId) {
        return module.configVal;
      }
    }
    return null;
  }

  savePrivilege($event: any, privilege: Privilege) {
    if (!this.roleId) {
      alert('Please select Role..');
      console.log(privilege.access);
      privilege.access = !(privilege.access);
      console.log(privilege.access);

      return false;
    }
    privilege.roleId = this.roleId;
    this.privilegeService.assignPrivilege(privilege).subscribe((result: Privilege) => {
      if (result) {
        privilege.accessId = result.accessId;
        this.addSuccessMessage();
      }
    });
  }
  addSuccessMessage() {
    this.messageService.add({ severity: 'success', summary: 'Successfully assigned privileges to this user.', detail: '', sticky: false });
  }

  addFailureMessages() {
    this.messageService.add({
      severity: 'error', summary: 'Successfully Revoked the assigned privileges to this user.',
      detail: '', sticky: false
    });
  }

  populatePrivileges() {
    //console.log('In populate privileges...');
    //for (const privilege of this.privileges) {
    for (let item of Object.keys(this.privileges)) {
      let privilege = this.privileges[item];
      for (const access of this.accessPrivileges) {
        if (privilege.moduleId == access.moduleId && privilege.subModuleId == access.subModuleId
          && privilege.activityId == access.activityId) {
          access.access = privilege.access;
          access.accessId = privilege.accessId;
          // console.log(access);
          break;
        }
      }
    }

    this.formPriviles = this.accessPrivileges;
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}
