import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { OrganizationService } from '../../organization.service';

import { Organization } from '../../models/organization.model';
import { element } from 'protractor';



@Component({
  selector: 'app-org-general',
  templateUrl: './org-general.component.html',
  styleUrls: ['./org-general.component.css']
})
export class OrgGeneralComponent implements OnInit, OnChanges {
  @Input() generalDetails: Organization;
  orgGeneralForm: FormGroup;
  // genralDataObj: Organization;
  isParentOrg = false; requiredmsg = false;
  orgs: Array<Organization>;
  selectedOrgId: number;
  constructor(private fb: FormBuilder, private orgService: OrganizationService) { }

  @Input() selectedTabIndex: number;
  @Output() generalData = new EventEmitter();

  addedGeneralvalues() { // You can give any function name
    if (this.orgGeneralForm.valid) {
      this.requiredmsg = false;
      this.generalData.emit(this.orgGeneralForm.value);
    } else {
      this.requiredmsg = true;
    }
  }

  ngOnInit() {
    this.createGeneralForm();
    this.getOrganizations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createGeneralForm();
    this.getOrganizations(); this.CheckIsParentOrg(this.isParentOrg);

    const genDetails = changes.generalDetails.currentValue;
    if (genDetails) {
      this.populateOrgGenralDetails(genDetails);
      this.isParentOrg = genDetails.isParentOrg;
      this.CheckIsParentOrg(this.isParentOrg);
    } else {
      this.orgGeneralForm.reset();
      this.isParentOrg = false;
      this.orgGeneralForm.controls['parentOrgId'].setValue('', { onlySelf: true });
    }
  }

  populateOrgGenralDetails(generalDetails: Organization) {
    console.log('populateOrgGenralDetails', generalDetails);

    if (this.orgGeneralForm) {
      this.orgGeneralForm.get('isParentOrg').setValue(generalDetails.isParentOrg);
      this.orgGeneralForm.get('parentOrgId').setValue(generalDetails.parentOrgId);
      this.orgGeneralForm.get('orgName').setValue(generalDetails.orgName);
      this.orgGeneralForm.get('orgCode').setValue(generalDetails.orgCode);
      this.orgGeneralForm.get('orgWebsite').setValue(generalDetails.orgWebsite);
      this.orgGeneralForm.get('orgPhoneNo').setValue(generalDetails.orgPhoneNo);
      this.orgGeneralForm.get('orgDescription').setValue(generalDetails.orgDescription);
    }
  }

  createGeneralForm() {
    this.orgGeneralForm = this.fb.group({
      isParentOrg: [''],
      orgName: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      orgCode: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      //orgLogo: ['', Validators.required],
      //orgLocation: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      parentOrgId: ['', Validators.required],
      orgDescription: ['', Validators.required],
      orgWebsite: [''],
      // orgDisplayName: ['', Validators.required],
      orgPhoneNo: ['', [Validators.required, Validators.pattern(/^(?!(\d)\1+$)(?:\(?\+\d{1,3}\)?[- ]?|0)?\d{10}$/)]],
      //orgRemarks: ['', Validators.required]
    });
  }

  reset() {
    this.orgGeneralForm.reset();
    this.isParentOrg = false;
    this.orgGeneralForm.controls['parentOrgId'].setValue('', { onlySelf: true });
  }
  getOrganizations() {
    this.orgService.getParentOrganizations().subscribe((resp: any) => {
      this.orgs = resp.MOrganiserDetails;
    });
  };
  CheckIsParentOrg(checkvalue: boolean) {
    if (checkvalue == true) {
      this.orgGeneralForm.controls['parentOrgId'].clearValidators();
      this.orgGeneralForm.controls['parentOrgId'].updateValueAndValidity();
      this.orgGeneralForm.controls['parentOrgId'].setValue('', { onlySelf: true });

    }
    else {
      this.orgGeneralForm.controls['parentOrgId'].setValidators([Validators.required]);
      this.orgGeneralForm.controls['parentOrgId'].updateValueAndValidity();
      this.orgGeneralForm.controls['isParentOrg'].clearValidators();
      this.orgGeneralForm.controls['isParentOrg'].updateValueAndValidity();


    }
  }
}
