import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../model/login.model';
import { AuthService } from '../../auth.service';
import { Employee } from 'src/app/admin/models/employee.model';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '../../admin/employee.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginEntity: Login = new Login();
  user: string;
  password: string;
  employee: Employee;
  forgotPasswordForm: boolean;
  email: string;
  loginForm: FormGroup;
  employees: Array<Employee>;
  closeResult: string;
  constructor(private fb: FormBuilder,private modalService: NgbModal,  private router: Router, private authService: AuthService, private messageService: MessageService,private empService: EmployeeService) { }

  ngOnInit() {
    this.employees = [];
    this.createLoginForm();
    // this.authService.behaviour.subscribe(value => {
    //   if(value=="logout"){
    //     alert();
    //   sessionStorage.removeItem('user');
    //   console.log("Subscription got", value); 
    //   this.router.navigate(['login']);
    //   }
    // });

  }
  createLoginForm() {
    this.loginForm = this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.min(5), Validators.max(150)]],

    });
  }
  retrieveEmployees() {
    this.empService.retrieveAllEmployees().subscribe((response: any) => {
      this.employees = response;

    });

  }
  loginCheck() {
    if (this.loginForm.valid) {
      this.loginEntity.userName = this.loginForm.get("userName").value;
      this.loginEntity.password = this.loginForm.get("password").value;
      this.authService.login(this.loginEntity).subscribe((response: any) => {
        console.log(response);
        this.employee = response;
        if (this.employee && this.employee.userName !== '') {
          if(this.employee.isActive!="false"){
          this.authService.setUser(this.employee);
          this.authService.setUserMenu(this.employee.roleId);
          this.router.navigate(['dashboard']);
          }
          else{
            alert("Your account is deactivated.please contact BIAC Security.");
          }
        } else {
          alert('Invalid Username or Password');
        }
      });
    } 
  }

  forgotPassword() {
    this.forgotPasswordForm = true;
  
  }

  passwordEmailNotification() {

    this.authService.forgotPassword(this.email).subscribe((response: any) => {
      console.log(response);
      if (response.status == 'Success') {
        this.messageService.add({ severity: 'success', summary: response.message, detail: '' });
      } else {
        this.messageService.add({ severity: 'error', summary: response.message, detail: '' });
      }

    });
    this.email = "";
  }
  dialogclose() {
    this.forgotPasswordForm = false;
  }
  openViewForm(content) {
    this.email="";
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}
