import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdocsPptService } from '../../services/mdocsppt.services';
import { MdocsPptmodel } from '../../models/mdocsppt.models';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../auth.service';
import { environment } from '../../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-ppt-list',
  templateUrl: './ppt-list.component.html',
  styleUrls: ['./ppt-list.component.css']
})
export class PptListComponent implements OnInit {
  
  mdoclst: MdocsPptmodel[] = [];
   unitId = 0; envurl = environment.waterMeter; closeResult: string;docDescription="";mdocPPt=new MdocsPptmodel();
  constructor(private router: Router, private messageService: MessageService, private modalService: NgbModal, private authService: AuthService, private mdocsService: MdocsPptService) {
    this.unitId = this.authService.getUser().unitId;
  }

  ngOnInit() {
    this.getList();
    this.mdocsService.MdocsPptreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.getList();
      }
    });
  }
  gotoUpload() {
    this.router.navigate(['pptupload']);
  }
  getList() {
    this.mdocsService.GetMdocsPptList(this.unitId, true).subscribe((resp: any) => {
      console.log(resp.MDocPptDetails);
      this.mdoclst = resp.MDocPptDetails;
    });
  }
  openViewForm(content, docModel: MdocsPptmodel) {
    this.docDescription = docModel.Description;
    this.mdocPPt=docModel;
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}
