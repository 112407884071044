import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdocsSafteyService } from '../../services/mdocssaftey.services';
import { MdocsSafteymodel } from '../../models/mdocssaftey.models';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../auth.service';
import { environment } from '../../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-safteypapers-list',
  templateUrl: './safteypapers-list.component.html',
  styleUrls: ['./safteypapers-list.component.css']
})
export class SafteypapersListComponent implements OnInit {

  mdoclst: MdocsSafteymodel[] = [];docSaftey=new MdocsSafteymodel();
  unitId = 0; envurl = environment.waterMeter; closeResult: string;docDescription="";
  constructor(private router: Router, private messageService: MessageService, private modalService: NgbModal,private authService: AuthService, private mdocsService: MdocsSafteyService) {
    this.unitId = this.authService.getUser().unitId;
  }


  ngOnInit() {
    this.getList();
    this.mdocsService.MdocsSafteyreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.getList();
      }
    });
  }
  getList() {
    this.mdocsService.GetMdocsSafteyList(this.unitId,true).subscribe((resp: any) => {
      console.log(resp.MDocSafteyDetails);
      this.mdoclst = resp.MDocSafteyDetails;
    });
  }
  gotoUpload(){
    this.router.navigate(['safteypapersupload']);
  }
  openViewForm(content, docModel: MdocsSafteymodel) {
    this.docSaftey = docModel;
    this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}