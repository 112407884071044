import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnitwatermeterModel, Unittypemodel, Consumertypemodel } from '../../modles/unitwatermeter.model';
import { UnitWaterService } from '../../services/unitwatermeter.services';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-form-unit',
  templateUrl: './form-unit.component.html',
  styleUrls: ['./form-unit.component.css']
})
export class FormUnitComponent implements OnInit {
  unitForm: FormGroup;
  @Input() disabled = false;
  submitted = false; requiredmsg = false;
  selectedunitId = 0; status = true;
  unittypes: Unittypemodel[] = [];
  consumertypes: Consumertypemodel[] = [];

  constructor(private fb: FormBuilder, private unitservice: UnitWaterService, private messageService: MessageService) { }

  ngOnInit() {
    this.createForm();
    this.getUnittypes();
    this.getConsumertypes();
    this.unitservice.UnitWaterActionObservable.subscribe((unitId: number) => {
      if (unitId) {
        this.selectedunitId = unitId;
        this.unitservice.getUnitWater(unitId).subscribe((unitwater: UnitwatermeterModel) => {
          console.log(unitwater);
          this.populateroadTaxtDetais(unitwater);

        });
      }
      this.requiredmsg = false;
    });
  }
  createForm() {
    this.unitForm = this.fb.group({
      //vendorId: ['', Validators.required],
      Unit_Type: ['', Validators.required],
      Consumer_Type: ['', Validators.required],
      Unit_Code: ['', Validators.required],
      Unit_Name: ['', Validators.required],
      Remarks: [''],
    });

    if (this.unitForm.valid) {
      this.disabled = true;
    }
  }
  formreset() {
    this.unitForm.reset();
    this.unitForm.get("Unit_Type").setValue('');
    this.unitForm.get("Consumer_Type").setValue('');
  }
  populateroadTaxtDetais(getDetails: UnitwatermeterModel) {
    //this.roadtaxForm.get("vendorId").setValue(getDetails.vendorId);
    this.unitForm.get("Unit_Type").setValue(getDetails.Unit_Type);
    //const roadTaxValidity = new Date(getDetails.roadTaxValidity);
    this.unitForm.get("Consumer_Type").setValue(getDetails.Consumer_Type);
    this.unitForm.get("Unit_Code").setValue(getDetails.Unit_Code + "");
    this.unitForm.get("Unit_Name").setValue(getDetails.Unit_Name + "");
    this.unitForm.get("Remarks").setValue(getDetails.Remarks);
    this.selectedunitId = getDetails.UnitID;
    this.status = getDetails.Status;

  }
  getUnittypes() {
    this.unitservice.getallUnitWaterType().subscribe((response: any) => {
      console.log(response);
      var lstval = response.UnitTypeList;
      lstval.forEach(item => {
        if (item.Status == true) {
          this.unittypes.push(item);
        }
      });

    });
  }
  getConsumertypes() {
    this.unitservice.getallConsumerType().subscribe((response: any) => {
      var lstval = response.ConsumerList;
      lstval.forEach(item => {
        if (item.Status == true) {
          this.consumertypes.push(item);
        }
      });
      console.log(response);
    });
  }
  registerUnit() {

    if (this.unitForm.valid) {
      this.requiredmsg = false;
      const UnitData = this.unitForm.value;
      UnitData.UnitID = this.selectedunitId;
      UnitData.Status = this.status; alert
      this.unitservice.addUnitWater(UnitData).subscribe((response: any) => {
        console.log(response);
        var statusval = this.selectedunitId == 0 ? "added" : "update";
        this.messageService.add({ severity: 'success', summary: 'Water Unit ' + statusval + ' successfully', detail: '', sticky: false });
        this.selectedunitId = 0;
        this.formreset();
        this.unitservice.notifyUnitWaterreated();
      });
    }


  }
}
