import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PptsComponent } from './ppts/ppts.component';
import { PptListComponent } from './ppts/ppt-list/ppt-list.component';
import { SopsComponent } from './sops/sops.component';
import { SopsListComponent } from './sops/sops-list/sops-list.component';
import { SafteypapersComponent } from './safteypapers/safteypapers.component';
import { SafteypapersListComponent } from './safteypapers/safteypapers-list/safteypapers-list.component';
import { MoviesComponent } from './movies/movies.component';
import { MoviesListComponent } from './movies/movies-list/movies-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DataTablesModule } from 'angular-datatables';
import {RouterModule} from '@angular/router';
@NgModule({
  declarations: [PptsComponent, PptListComponent, SopsComponent, SopsListComponent, SafteypapersComponent, SafteypapersListComponent, MoviesComponent, MoviesListComponent],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,ToastModule,
    MessageModule,
    DataTablesModule,
    RouterModule,
  ]
})
export class MdocsModule { }
