import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-watermeter',
  templateUrl: './unit-watermeter.component.html',
  styleUrls: ['./unit-watermeter.component.css']
})
export class UnitWatermeterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
