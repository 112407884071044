import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Employee } from './models/employee.model';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


const APP_URL = environment.adminApiUrl + 'api/Employee/';
//const APP_URL =  'http://localhost:53806/api/Employee/'; 

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private employeeSubject = new Subject<any>();
  employeeObservable = this.employeeSubject.asObservable();

  private employeeCreatedSubject = new Subject<any>();
  employeeCreatedObservable = this.employeeCreatedSubject.asObservable();

  private employeeEditSubject = new Subject<any>();
  employeeEditObservable = this.employeeEditSubject.asObservable();

  constructor(private httpClient: HttpClient) {

  }

  addInternalEmployee(empData: Employee) {
    return this.httpClient.post(APP_URL+"/Save", empData);
  }


  EmployeeUpdate(empData: Employee) {
    return this.httpClient.post(APP_URL+"/EmployeeUpdate", empData);
  }

  clickSave() {
    this.employeeSubject.next(true);
  }

  retrieveAllEmployees() {
    return this.httpClient.get(APP_URL+"/GetEmployeeList");
  }

  retrieveOrganizationEmployees(unitId: number) {
    return this.httpClient.get(APP_URL + '/unit?unitId=' + unitId);
  }

  notifyEmployeeCreated( ) {
    this.employeeCreatedSubject.next(true);
  }
  editEmployee(employeeId: number) {
    this.employeeEditSubject.next(employeeId);
  }
  getEmployeeForEdit(employeeId: number){
    return this.httpClient.get(APP_URL  + '/GetEmployee?empid=' + employeeId , {});
  }
  empUpdateStatus(employeeId:number,status:string){
    return this.httpClient.get(APP_URL + '/employeeStatusUpdation?employeeId=' + employeeId+'&isActive='+status);
  }

}
