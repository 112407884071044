import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { AdminModule } from './admin/admin.module';
import { DataTablesModule } from 'angular-datatables';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { AuthModule } from './auth/auth.module';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { WelcomeComponent } from './welcome/welcome.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { WatermeterModule } from './watermeter/watermeter.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MdocsModule} from './mdocs/mdocs.module'

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    DashboardComponent,
   
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    BrowserAnimationsModule,
    InputTextModule,
    PaginatorModule,
    AdminModule,
    DataTablesModule,
    CalendarModule,
    DialogModule,
    AuthModule,
    MessageModule,
    ToastModule,
    RadioButtonModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgbModule,
    NgMultiSelectDropDownModule,WatermeterModule,MdocsModule
  ],
  providers: [ DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy },BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
