import { Component, OnInit } from '@angular/core';
import { ConsumptionService } from '../watermeter/services/consumption.services';
import { Consumptionmodel, MonthwisereportModel, MValueKeyPair } from '../watermeter/modles/consumptions.model';
import { forkJoin } from 'rxjs'
import * as $ from 'jquery'
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { AuthService } from '../auth.service';
import { element } from 'protractor';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  private chart: AmChart;
  monthwiselist: MonthwisereportModel[] = [];
  waterdata: MValueKeyPair[] = [];
  linechartdata: MValueKeyPair[] = [];
  watertotdata = 0; efftotData = 0; monthtotaldata = 0; year = 0; month = 0; unitId: 0; todaydate = "";
  yearcounter = []; minimumDate = new Date(); monthcounter = [];
  constructor(private consumptserivce: ConsumptionService, private AmCharts: AmChartsService, private authService: AuthService) {
    this.todaydate = this.formatDate(new Date());
    var oldyear = this.minimumDate.getFullYear() - 2;
    for (let i = 0; i < 3; i++) {
      this.yearcounter.push(oldyear + i);
    }
    this.unitId = this.authService.getUser().unitId;
    if (this.unitId != 0) {
      this.GetWatermeter();
    }
    else {
      this.unitId = 0;

    }
    this.GetConsumption();
  }

  ngOnInit() {

  }
  getMonths() {
    this.month = 0; this.monthcounter = [];
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    if (this.minimumDate.getFullYear() == this.year) {
      for (let i = 0; i <= this.minimumDate.getMonth(); i++) {
        this.monthcounter.push({ value: i + 1, key: monthNames[i] });
      }
    } else {
      for (let i = 0; i < 12; i++) {
        this.monthcounter.push({ value: i + 1, key: monthNames[i] });
      }
    }
  }
  GetConsumption() {
    const fromDate = this.formatDate(new Date());
    const toDate = this.formatDate(new Date());
    var monthfirstday = new Date();
    monthfirstday.setDate(1);
    this.watertotdata = 0; this.efftotData = 0; this.monthtotaldata = 0;
    //let todaywaterdata = this.consumptserivce.Getmonthwisereport(fromDate, toDate, 0);
    this.consumptserivce.Getdashboardmonthwisereport(this.formatDate(monthfirstday), toDate, this.unitId).subscribe((results: any) => {
     console.log(results);
      this.waterdata = results.ValueKeyPairs;
      var cudate=(new Date().getDate()  < 10 ? '0' : '') + new Date().getDate() ;
      this.waterdata.forEach(element => {
        if (element.Key == cudate + "") {
          this.watertotdata += element.Value;
          this.monthtotaldata += element.Value;
        } else {
          this.monthtotaldata += element.Value;
        }
      });
      this.watertotdata=Math.round(this.watertotdata);
      this.monthtotaldata=Math.round(this.monthtotaldata);
      this.ColumnsChart();

    });

  }
  GetWatermeter() {
    this.monthwiselist = [];
    const fromDate = this.formatDate(new Date());
    var monthfirstday = new Date();
    monthfirstday.setDate(1);
    const toDate = this.formatDate(new Date()); this.watertotdata = 0; this.efftotData = 0; this.monthwiselist = [];
    this.consumptserivce.Getmonthwisereport(fromDate, toDate, Number(this.unitId)).subscribe((resp: any) => {
      this.monthwiselist = resp.modebusdetails;

    });
  }
  yearbyMonth() {
    if (this.month != 0 && this.year != 0) {
      $("#Linechart").css("display","none");
      this.consumptserivce.Getdashboardyearwisereport(this.year, this.month, this.unitId).subscribe((results: any) => {
        console.log(results);
        this.linechartdata = results.ValueKeyPairs;
        this.LineChart();
      });
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  ColumnsChart() {
   
    var chart = this.AmCharts.makeChart("chartdiv", {
      "type": "serial",
      "theme": "none",
      "dataProvider": this.waterdata,
      "valueAxes": [{
        "gridColor": "#FFFFFF",
        "gridAlpha": 0.2,
        "dashLength": 0
      }],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "graphs": [{
       // "fillColors": "#87ceeb",
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.8,
        "type": "column",
        "valueField": "Value",
      
      }],
      "chartCursor": {
        "categoryBalloonEnabled": false,
        "cursorAlpha": 0,
        "zoomable": false
      },
      "categoryField": "Key",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20
      },
      "export": {
        "enabled": false
      }

    });
    $("#chartdiv a").css("display","none");
  }
  LineChart() {
    $("#Linechart").css("display","block");
    var chart = this.AmCharts.makeChart("Linechart", {
      "type": "serial",
      "theme": "none",
     // "marginRight": 40,
      "marginLeft": 60,
      "autoMarginOffset": 20,
      "hideCredits":true,

      "valueAxes": [{
        "id": "v1",
        "axisAlpha": 0,
        "position": "left",
        "ignoreAxisWidth": true
      }],
      "balloon": {
        "borderThickness": 1,
        "shadowAlpha": 0
      },
      "graphs": [{
        "id": "g1",
        "balloon": {
          "drop": true,
          "adjustBorderColor": false,
          "color": "#ffffff",
          "type": "smoothedLine",
          "fillColors": "#87ceeb",
        },
        
        "fillAlphas": 0.2,
        "bullet": "round",
        "bulletBorderAlpha": 1,
        "bulletColor": "#FFFFFF",
        "bulletSize": 5,
        "hideBulletsCount": 50,
        "lineThickness": 2,
        "title": "red line",
        "useLineColorForBulletBorder": true,
        "valueField": "Value",
        "balloonText": "<span style='font-size:18px;'>[[Value]]</span>"
      }],
      "chartCursor": {
        "valueLineEnabled": true,
        "valueLineBalloonEnabled": true,
        "cursorAlpha": 0,
        "zoomable": false,
        "valueZoomable": true,
        "valueLineAlpha": 0.5
      },
      "valueScrollbar": {
        "autoGridCount": true,
        "color": "#000000",
        "scrollbarHeight": 50
      },
      "categoryField": "Key",
      "categoryAxis": {
        //"parseDates": true,
        "dashLength": 1,
        "minorGridEnabled": true
      },
      "export": {
        "enabled": false
      },
      "dataProvider": this.linechartdata
    });
    $("#Linechart a").css("display","none");
  }
  CurrentDateformat(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
}
