// export class WaterReportmeterModel {
//     ID: number;
//     DeviceID: number;
//     TotEraiserModified: string;
//     TotEraiser: string;
//     UnitName: string;
//     Date:Date;
// }
export class WaterReportmeterModel {
    ID: number;
    UnitName: string;
    TotEraiserModified: string;
    TotEraiser: string;
    DeviceID: number;
    Datetime:Date;
    Status:string;
    Remarks:string;
}