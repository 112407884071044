import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EmployeeService } from '../../employee.service';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.css']
})
export class ButtonsComponent implements OnInit {

  @Output() isSaveClicked = new EventEmitter<boolean>();
  constructor(private employeeService: EmployeeService) { }

  ngOnInit() {
  }

  click() {
    this.employeeService.clickSave();
  }

  resetEmployee() {

  }
}
