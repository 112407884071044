import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnitwatermeterlistModel, Unittypemodel, Consumertypemodel } from '../../modles/unitwatermeter.model';
import { UnitWaterService } from '../../services/unitwatermeter.services';
import { MessageService } from 'primeng/api';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-list-unit',
  templateUrl: './list-unit.component.html',
  styleUrls: ['./list-unit.component.css']
})
export class ListUnitComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  unitwaterlst: UnitwatermeterlistModel[] = [];
  dtTrigger: Subject<any> = new Subject();
  unittypes: Array<Unittypemodel>;
  consumertypes: Array<Consumertypemodel>;
  constructor(private fb: FormBuilder, private unitservice: UnitWaterService, private messageService: MessageService) {
    this.retrieveunitdetails();
  }

  ngOnInit() {
    this.unitservice.UnitWaterreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.retrieveunitdetails();
      }
    });
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      pageLength: 10,
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Water Unit',
          filename: 'WaterUnit',
          // exportOptions: {
          //   columns: [0, 1, 2, 3, 4, 5, 6, 7]
          // },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Water Unit',
          filename: 'WaterUnit',
          // exportOptions: {
          //   columns: [0, 1, 2, 3, 4, 5, 6, 7]
          // },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };

  }
  retrieveunitdetails() {
    this.unitservice.getallUnitWaterList().subscribe((resp: any) => {
      console.log(resp.UnitMasterList);
      if (resp) {
        this.unitwaterlst = resp.UnitMasterList;
        this.rerender();
      }
    });
  }
  editunit(unitId: number) {
    this.unitservice.editUnitWaterRequest(unitId);
}
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
