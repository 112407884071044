export class Contact {
	
	contactId: number;
	contactPersonName: string;
	department: number;
	designation: number;
	email: string;
	work: string;
	mobile: string;
	fax: string;
	isPrimaryContact: boolean;
}

