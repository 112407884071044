import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Privilege } from './admin/models/privileges.model';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {

  private apiurl = environment.adminApiUrl+"api/AccessPrivilege"

  constructor(private httpClient: HttpClient) { }

    assignPrivilege(privilege: Privilege) {
      return this.httpClient.post(this.apiurl+"/Save" , privilege);
    }
   getAllPrivileges(roleId: number) {
      return this.httpClient.get(this.apiurl + '/GetAccessPrivilege?RoleId=' + roleId);
   }

  //  getDynamicMenu(roleId: number) {
  //     return this.httpClient.get(this.apiurl + '/menu/' +  roleId);
  //  }
  getDynamicMenu(roleId: number) {
    return this.httpClient.get(environment.adminApiUrl+ '/api/Menu/DynamicMenu?RoleId=' +  roleId);
 }

   getDynamicMenu2(roleId: number) {
    return this.httpClient.get("https://5e00988b685ac80014514c95.mockapi.io/Dynamicmenu/"+roleId);
 }


}

