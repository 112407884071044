import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { EmployeeService } from '../../employee.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { General } from '../../models/generalData.model';
import { Role } from '../../models/role.model';
import { RoleService } from '../../role.service';
import { CommonService } from 'src/app/common.service';
import { ParameterService } from '../../parameter.service';
import { Parameter } from '../../models/parameter.model';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from '../../organization.service';
import { Employee } from '../../models/employee.model';
import { tick } from '@angular/core/testing';
import { MessageService } from 'primeng/api';



@Component({
  selector: 'app-emp-general',
  templateUrl: './emp-general.component.html',
  styleUrls: ['./emp-general.component.css']
})
export class EmpGeneralComponent implements OnInit {

  @Input() selectedTabIndex: number;
  @Output() generalData = new EventEmitter();

  roles: Array<Role>;

  empGeneralForm: FormGroup;
  employees: Array<Employee>;
  designations: Array<Parameter> = [];
  departments: Array<Parameter> = [];
  units: Array<Organization> = [];
  genralData: General; requiredmsg = false;
  status = "true";
  selectedEmpID: number; currentdate = Date(); maximumDate = new Date();
  constructor(private fb: FormBuilder, private empService: EmployeeService, private roleSevice: RoleService, private messageService: MessageService,
    private parameterService: ParameterService, private orgService: OrganizationService) {
    var cudate = new Date();
    this.maximumDate.setFullYear(cudate.getFullYear() - 10);
  }

  ngOnInit() {
    this.createGeneralForm();
    this.roles = [];
    this.getRoles();
    this.getDepartments();
    this.getDesignations();
    this.getUnits();
    this.retrieveEmployees();
    this.empService.employeeCreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.empGeneralFormReset();
      }

    });
    this.empService.employeeEditObservable.subscribe((employeeId: number) => {
      if (employeeId) {
        this.selectedEmpID = employeeId;

        this.empService.getEmployeeForEdit(employeeId).subscribe((empGenralDetails: Employee) => {
          this.populateEmpGenralDetails(empGenralDetails); //console.log(empGenralDetails);
        });
      }
    });
  }

  populateEmpGenralDetails(empGenralDetails: Employee) {

    // console.log("EmpEditorDetails",empGenralDetails);
    this.empGeneralForm.get('unitId').setValue(empGenralDetails.unitId);
    this.empGeneralForm.get('employeeCode').setValue(empGenralDetails.employeeCode);
    this.empGeneralForm.get('firstName').setValue(empGenralDetails.firstName);
    this.empGeneralForm.get('middleName').setValue(empGenralDetails.middleName);
    this.empGeneralForm.get('lastName').setValue(empGenralDetails.lastName);
    this.empGeneralForm.get('userName').setValue(empGenralDetails.userName);
    this.empGeneralForm.get('gender').setValue(empGenralDetails.gender);
    const dobDate = new Date(empGenralDetails.dob);
    this.empGeneralForm.get('dob').setValue(this.formatDate(dobDate));
    this.empGeneralForm.get('departmentId').setValue(empGenralDetails.departmentId);
    this.empGeneralForm.get('designationId').setValue(empGenralDetails.designationId);
    this.empGeneralForm.get('roleId').setValue(empGenralDetails.roleId);
    this.empGeneralForm.get('mobile').setValue(empGenralDetails.mobile);
    this.empGeneralForm.get('email').setValue(empGenralDetails.email);
    this.empGeneralForm.get('aadharNumber').setValue(empGenralDetails.aadharNumber);
    this.status = empGenralDetails.isActive;
  }

  empGeneralFormReset() {
    this.empGeneralForm.reset();
    this.empGeneralForm.controls['unitId'].setValue('', { onlySelf: true });
    this.empGeneralForm.controls['departmentId'].setValue('', { onlySelf: true });
    this.empGeneralForm.controls['designationId'].setValue('', { onlySelf: true });
    this.empGeneralForm.controls['roleId'].setValue('', { onlySelf: true });
    this.empGeneralForm.controls['dob'].setValue(this.formatDate(new Date()));

  }
  retrieveEmployees() {
    this.empService.retrieveAllEmployees().subscribe((response: any) => {
  
      this.employees = response.MEmployeeDetails;
    });
  }

  createGeneralForm() {
    this.empGeneralForm = this.fb.group({
      //companyId:  ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      employeeCode: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      middleName: ['', [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      lastName: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      userName: ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      departmentId: ['', Validators.required],
      designationId: ['', Validators.required],
      //isActive: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern(/^(?!(\d)\1+$)(?:\(?\+\d{1,3}\)?[- ]?|0)?\d{10}$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      aadharNumber: [''],
      unitId: ['', Validators.required],
      roleId: ['', Validators.required]


    });
  }


  addEmpGeneralData() { // You can give any function name
    if (this.empGeneralForm.valid) {
      this.requiredmsg = false;
      var username = String(this.empGeneralForm.get("userName").value);
      var mobilenumber = Number(this.empGeneralForm.get("mobile").value);
      var email = String(this.empGeneralForm.get("email").value); var empflag = true;
      for (const emp of this.employees) {
        if (username.toLowerCase() == emp.userName.toLowerCase() && !this.selectedEmpID) {
          empflag = false;
          this.messageService.add({ severity: 'error', summary: 'User Name  already exist', detail: '', sticky: false });
          break;
        }
        if (mobilenumber == emp.mobile && !this.selectedEmpID) {
          empflag = false;
          this.messageService.add({ severity: 'error', summary: 'Mobile number already exist', detail: '', sticky: false });
          break;
        }
        if (email.toLowerCase() == emp.email.toLowerCase() && !this.selectedEmpID) {
          empflag = false;
          this.messageService.add({ severity: 'error', summary: 'Email already exist', detail: '', sticky: false });
          break;
        }
      }
      if (empflag) {

        const empGeneralData = this.empGeneralForm.value;
        empGeneralData.dob = this.formatDate(this.empGeneralForm.get("dob").value);
        empGeneralData.employeeId = this.selectedEmpID;
        empGeneralData.isActive = this.status;
        this.generalData.emit(empGeneralData);
      }
    } else {
      this.requiredmsg = true;
    }
  }

  getRoles() {
    this.roleSevice.retrieveAllRoles().subscribe((response:any) => {
      this.roles = response.MRollsListDetails;
    });
  }

  getDesignations() {
    this.parameterService.getDesignations().subscribe((response: any) => {
      this.designations = response.Configlst;
    });
  }

  getDepartments() {
    this.parameterService.getDepartments().subscribe((response: any) => {
      //console.log(response);
      this.departments = response.Configlst;
    });
  }

  getUnits() {
    this.orgService.getOrganizationUnits().subscribe((response: any) => {
      this.units = response.MOrganiserDetails;//console.log(this.units);
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
