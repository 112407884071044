import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Organization } from '../models/organization.model';
import { General } from '../models/generalData.model';
import { OrganizationAddress } from '../models/orgaddress.model';
import { OrganizationContact } from '../models/orgcontact.model';
import { Address } from '../models/address.model';
import { Contact } from '../models/contact.model';
import { MessageService } from 'primeng/api';
import { Messages } from '../models/message.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  organizationData: Organization;
  index = 0;
  showTabs;
  orgId = 0;
  organization: Organization;
  address: Address;
  contact: Contact; orgstatus = "true";

  constructor(private orgService: OrganizationService, private messageService: MessageService) {
    this.organizationData = new Organization();
  }

  addedGeneralValues(genralData) {
    this.addGeneralData(genralData);
    this.index = 1;
  }

  addGeneralData(genralData: any) {
    this.organizationData = genralData;
  }

  addedAddressValues(addressData: any) {
    this.organizationData.addressLine1 = addressData.addressLine1;
    this.organizationData.addressLine2 = addressData.addressLine2;
    this.organizationData.city = addressData.city;
    this.organizationData.state = addressData.state;
    this.organizationData.country = addressData.country;
    this.organizationData.zipCode = addressData.zipCode;
    this.organizationData.isPrimary = addressData.isPrimary;
    this.organizationData.addrType = addressData.addrType;
    console.log('AddressDdata', this.organizationData);
    this.index = 2;
  }


  addedContactValues(contactData: any) {

    this.organizationData.contactPersonName = contactData.contactPersonName;
    this.organizationData.department = contactData.department;
    this.organizationData.designation = contactData.designation;
    this.organizationData.email = contactData.email;
    this.organizationData.work = contactData.work;
    this.organizationData.mobile = contactData.mobile;
    this.organizationData.fax = contactData.fax;
    this.organizationData.isPrimaryContact = contactData.isPrimaryContact;
    var msgcodes = new Messages();
    msgcodes.StatusCode = 0;
    msgcodes.isWarning = false;
    msgcodes.isError = false;
    msgcodes.isSuccess = true;
    msgcodes.isInfo = false;
    msgcodes.Message = "0";
    msgcodes.ErrorCode = 0;
    this.organizationData.Msg = msgcodes;
    if (this.orgId) {
      this.organizationData.orgId = this.orgId;
    }
this.organizationData.orgStatus=this.orgstatus;
    console.log(this.organizationData);
    this.orgService.addOrganization(this.organizationData).subscribe((response: any) => {
      console.log(response);
      this.addSuccessMessage();
      this.clearForm();
      this.orgService.notifyOrganizationCreated();
      this.orgstatus="true";
    });
    this.index = 0;
  }

  ngOnInit() {
    this.showTabs = {
      one: true,
      two: true,
      three: true
    };
    this.organization = new Organization();
    this.orgService.orgEditObservable.subscribe((orgId: number) => {
      if (orgId) {
        this.orgId = orgId;
        this.orgService.getOrganization(orgId).subscribe((orgDetails: Organization) => {

          this.organization = orgDetails;
          this.address = new Address();
          this.address.addressLine1 = this.organization.addressLine1;
          this.address.addressLine2 = this.organization.addressLine2;
          this.address.city = this.organization.city;
          this.address.state = this.organization.state;
          this.address.country = this.organization.country;
          this.address.zipCode = this.organization.zipCode;
          this.address.isPrimary = this.organization.isPrimary;
          this.address.addrType = this.organization.addrType;
          this.orgstatus = this.organization.orgStatus==null?"true":this.organization.orgStatus;
          this.contact = new Contact();
          this.contact.contactPersonName = this.organization.contactPersonName;
          this.contact.department = this.organization.department;
          this.contact.designation = this.organization.designation;
          this.contact.email = this.organization.email;
          this.contact.work = this.organization.work;
          this.contact.mobile = this.organization.mobile;
          this.contact.fax = this.organization.fax;
          this.contact.isPrimaryContact = this.organization.isPrimaryContact;

        });
      }
    });
  }

  clearForm() {
    this.organization = null;
    this.address = null;
    this.contact = null;
    this.orgId = undefined;
  }
  addSuccessMessage() {
    this.messageService.add({
      severity: 'success',
      summary: 'Mangae Organization added successfully.', detail: '', sticky: false
    });
  }

}
