import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { Address } from '../../models/address.model';
import { OrganizationService } from '../../organization.service';
import { Organization } from '../../models/organization.model';
import { OrganizationComponent } from '../organization.component';

@Component({
  selector: 'app-org-address',
  templateUrl: './org-address.component.html',
  styleUrls: ['./org-address.component.css']
})
export class OrgAddressComponent implements OnInit, OnChanges {

  orgAddressForm: FormGroup;
  addressDataArray: Array<Address>;
    selectedOrgId: number; requiredmsg = false;
    @Input() addressDetails: Address;
    constructor(private fb: FormBuilder, private orgService: OrganizationService, private OrgComponent: OrganizationComponent) { }
  @Output() addressData = new EventEmitter();
    addedAddressValues() { // You can give any function name
        if (this.orgAddressForm.valid) {
            this.requiredmsg = false;
            this.addressDataArray = this.orgAddressForm.value;
            this.addressData.emit(this.addressDataArray);
            //console.log(this.addressDataArray);
        } else {
            this.requiredmsg = true;
        }
}
  ngOnInit() {
    this.createAddressForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const address = changes.addressDetails.currentValue;
      if (address) {
        this.populateAddressDetails(address);
      } else {
        if (this.orgAddressForm) {
          this.orgAddressForm.reset();
        }
      }
    }
  }

 populateAddressDetails(address: Address) {
    console.log('populateAddressDetails' , address);

    this.orgAddressForm.get('addressLine1').setValue(address.addressLine1);
    // this.orgAddressForm.get('addressLine2').setValue(address.addressLine2);
    this.orgAddressForm.get('city').setValue(address.city);
    this.orgAddressForm.get('state').setValue(address.state);
    this.orgAddressForm.get('country').setValue(address.country);
    this.orgAddressForm.get('zipCode').setValue(address.zipCode);
}

  createAddressForm() {
    this.orgAddressForm = this.fb.group({
      addressLine1: ['', Validators.required],
      // addressLine2: ['', Validators.required],
      city:  ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      state:  ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      country:  ['', [Validators.required, Validators.min(5), Validators.max(150), Validators.pattern('^[a-zA-Z ]*$')]],
      // tslint:disable-next-line:max-line-length
      zipCode:  ['', [Validators.required , Validators.pattern('[1-9][0-9]{5}')  ]],
    });
  }
    backgeneral() {
        this.OrgComponent.index = 0;
    }

}
