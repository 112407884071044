import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnitwatermeterlistModel, Unittypemodel, Consumertypemodel } from '../modles/unitwatermeter.model';
import { UnitWaterService } from '../services/unitwatermeter.services';
import { MessageService } from 'primeng/api';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EffluentwatermeterService } from '../services/effluentwatermeter.services';
import { EffluentwatermeterModel } from '../modles/effluentwatermeter.model';
import { ConsumptionService } from '../services/consumption.services';
import { Consumptionmodel, MonthwisereportModel } from '../modles/consumptions.model';
import { Organization } from '../../admin/models/organization.model';
import { OrganizationService } from '../../admin/organization.service';

@Component({
  selector: 'app-monthwisereport',
  templateUrl: './monthwisereport.component.html',
  styleUrls: ['./monthwisereport.component.css']
})
export class MonthwisereportComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  effwaterlist: EffluentwatermeterModel[] = [];
  dtTrigger: Subject<any> = new Subject();
  Unitval = ""; fromDate: Date; toDate: Date;
  unitwaterdatalst: UnitwatermeterlistModel[] = [];
  monthwiselist: MonthwisereportModel[] = [];
  watertotdata = 0; efftotData = 0;
  organizations: Array<Organization>;
  constructor(private organizationService: OrganizationService,private fb: FormBuilder, private consumptserivce: ConsumptionService, private unitservice: UnitWaterService, private messageService: MessageService) {
    this.retrieveunitdatadetails();
  }

  ngOnInit() {
    this.getOrganizations();
    this.dtOptions = {
      dom: 'Bfrtip',
      searching: true,
      "language": {
        "emptyTable": "No data available"
      },  
      buttons: [
        {
          extend: 'print',
          text: '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Road Tax',
          filename: 'Roadtax',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Road Tax',
          filename: 'Roadtax',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7]
          },
          text: '<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };

  }
  getOrganizations() {
    this.organizationService.getOrganizationUnits().subscribe((response: any) => {
      this.organizations = response.MOrganiserDetails;
    });
  }
  GetConsumption() {
    const fromDate = this.formatDate(this.fromDate);
    const toDate = this.formatDate(this.toDate); this.watertotdata = 0; this.efftotData = 0; this.monthwiselist = [];
    this.consumptserivce.Getmonthwisereport(fromDate, toDate, Number(this.Unitval)).subscribe((resp: any) => {
      console.log(resp);
      this.monthwiselist = resp.modebusdetails;
      this.monthwiselist.forEach(element => {
        this.watertotdata += element.readingdiff;
        this.efftotData += element.Effdis;
      });
      console.log(this.monthwiselist);

    });
  }
  retrieveunitdatadetails() {
    this.unitservice.getallUnitWaterList().subscribe((resp: any) => {
      if (resp) {
        this.unitwaterdatalst = resp.UnitMasterList;
      }
    });
  }

  editConsuption(Id: number) {
    this.consumptserivce.editConsumptionRequest(Id);
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}

