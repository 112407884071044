import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrgAddressComponent } from './admin/organization/org-address/org-address.component';
import { RoleComponent } from './admin/role/role.component';
import { ManageOrganizationComponent } from './admin/manage-organization/manage-organization.component';
import { ExternalEmployeeComponent } from './admin/employees/external-employee/external-emp.component';
import { MasterdatamanagementComponent } from './admin/masterdatamanagement/masterdatamanagement.component';
import { AssignPrivilegesComponent } from './admin/assign-privileges/assign-privileges.component';
import { NewOrgComponent } from './admin/new-org/new-org.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import { WelcomeComponent } from './welcome/welcome.component';
import { UnitWatermeterComponent } from './watermeter/unit-watermeter/unit-watermeter.component';
import { EffluentWatermeterComponent } from './watermeter/effluent-watermeter/effluent-watermeter.component';
import { ConsumptionmodificationComponent } from './watermeter/consumptionmodification/consumptionmodification.component';
import { MonthwisereportComponent } from './watermeter/monthwisereport/monthwisereport.component';
import { MeterwisegraphComponent } from './watermeter/meterwisegraph/meterwisegraph.component';

import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { DashboardComponent } from './dashboard/dashboard.component';
import { PptsComponent } from './mdocs/ppts/ppts.component';
import { PptListComponent } from './mdocs/ppts/ppt-list/ppt-list.component';
import { MoviesComponent } from './mdocs/movies/movies.component';
import { MoviesListComponent } from './mdocs/movies/movies-list/movies-list.component';
import { SafteypapersComponent } from './mdocs/safteypapers/safteypapers.component';
import { SafteypapersListComponent } from './mdocs/safteypapers/safteypapers-list/safteypapers-list.component';
import { SopsComponent } from './mdocs/sops/sops.component';
import { SopsListComponent } from './mdocs/sops/sops-list/sops-list.component';
import{WaterreportComponent} from './watermeter/waterreport/waterreport.component';
import{WaterreportapprovalComponent} from './watermeter/waterreportapproval/waterreportapproval.component';
import { WaterreportbyunitComponent } from './watermeter/waterreportbyunit/waterreportbyunit.component';
import { WaterreportapprovalbyunitComponent } from './watermeter/waterreportapprovalbyunit/waterreportapprovalbyunit.component';

const routes: Routes = [

  { path:  'login', component:  LoginComponent},
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'welcome', component: WelcomeComponent },
 
  { path:  'organization', component:  NewOrgComponent},
  { path:  'address', component:  OrgAddressComponent},
  { path:  'roles', component:  RoleComponent},
  { path:  'manageorganization', component:  ManageOrganizationComponent},
  { path:  'employee', component:  ExternalEmployeeComponent},
  { path:  'masterdatamanagement', component:  MasterdatamanagementComponent},
  { path:  'assignprivileges', component:  AssignPrivilegesComponent},
  { path:  'resetPassword', component:  ResetPasswordComponent},
  { path:  'unitwatermeter', component:  UnitWatermeterComponent},
  {path:'effluentwatermeter',component:EffluentWatermeterComponent},
  {path:'consumption',component:ConsumptionmodificationComponent},
  {path:'monthwisereport',component:MonthwisereportComponent},
  {path:'meterwisegraph',component:MeterwisegraphComponent},
  {path:'dashboard',component:DashboardComponent},
  { path:  'organization', component:  NewOrgComponent},
  { path:  'pptupload', component:  PptsComponent},
  { path:  'ppts', component:  PptListComponent},
  { path:  'moviesupload', component:  MoviesComponent},
  { path:  'movies', component:  MoviesListComponent},
  { path:  'safteypapersupload', component:  SafteypapersComponent},
  { path:  'safteypapers', component:  SafteypapersListComponent},
  { path:  'sopsupload', component:  SopsComponent},
  { path:  'sops', component:  SopsListComponent},
  {path:'waterreoport',component: WaterreportComponent},
  {path:'waterreoportapproval',component: WaterreportapprovalComponent},
  {path:'waterreoportbyunit',component: WaterreportbyunitComponent},
  {path:'waterreoportapprovalbyunit',component: WaterreportapprovalbyunitComponent},
  

];


@NgModule({
  imports: [RouterModule.forRoot(routes),AmChartsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }


