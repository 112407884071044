import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth.service';
import { ResetPassword } from '../model/resetPassword.model';
import {AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  password: string;
  confirmPassword: string;
  resetPasswordEntity: ResetPassword = new ResetPassword();
  requiredmsg = false;
  constructor(private fb: FormBuilder,private authService: AuthService,private messageService:MessageService) { }
  resetForm: FormGroup;
  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },{validator: this.passwordConfirming}
    )
   
}
passwordConfirming(c: AbstractControl): { invalid: boolean } {
  if (c.get('password').value !== c.get('confirmPassword').value) {
      return {invalid: true};
  }
}


  resetPassword(){
    if (this.resetForm.valid) {
      this.requiredmsg = false;
      const resetPasswordmodel = this.resetForm.value;
   // console.log(resetPasswordmodel);
    this.authService.resetPassword(resetPasswordmodel).subscribe((response: any) => {
      if(response.status == 'Success'){
         this.messageService.add({ severity: 'success',summary: "Password changed successfully.", detail: '' });
      }else{
        this.messageService.add({ severity: 'error',summary: response.message, detail: ''});
      }
      this.resetForm.reset();
     });
    }
    else {

      this.requiredmsg = true;
  }
     
  }
}
