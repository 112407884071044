import { Address } from './address.model';
import { OrganizationContact } from './orgcontact.model';
import { OrganizationAddress } from './orgaddress.model';
import { Contact } from './contact.model';
import { Messages } from './message.model';

export class Organization {

	orgId: number;
	orgName: string ;
	orgDisplayName: string;
	orgDescription: string;
	orgStatus: string;
	orgCode: string;
	orgLocation: string;
	orgDivision: string;
	orgRemarks: string;
	isParentOrg: boolean;
	parentOrgId: number;
	orgWebsite: string;
	orgEmail: string;
	orgPhoneNo: string;
	orgLogo: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	country: string;
	zipCode: string;
	isPrimary: string;
	addrType: string;
	contactPersonName: string;
	department: number;
	designation: number;
	email: string;
	work: string;
	mobile: string;
	fax: string;
	isPrimaryContact: boolean;
	Msg:Messages;
	
}

