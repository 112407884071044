import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MdocsMoviesmodel } from '../models/mdocsmovies.models';
@Injectable({
    providedIn: 'root'
})
export class MdocsMoviesService {

    private baseUrl = environment.waterMeter;
    constructor(private httpClient: HttpClient) { }

    private MdocsMoviesCreatedSubject = new Subject<any>();
    MdocsMoviesreatedObservable = this.MdocsMoviesCreatedSubject.asObservable();

    private MdocsMoviesEditSubject = new Subject<any>();
    MdocsMoviesActionObservable = this.MdocsMoviesEditSubject.asObservable();

    addMdocsMovies(fromdata: FormData) {
        return this.httpClient.post(this.baseUrl + 'api/Mdocs/MovieSave', fromdata, );
    }
    GetMdocsMoviesList(UnitID: number,Status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/GetMoviesList?unitId="+UnitID+"&Status="+Status);
    }
    UpdateStatus(Id: number,status:boolean) {
        return this.httpClient.get(this.baseUrl + "api/Mdocs/UpdateMovieStatus?Id="+Id+"&Status="+status);
    }

    notifyMdocsMoviesreated() {
        this.MdocsMoviesCreatedSubject.next(true);
    }
    editMdocsMoviesRequest(superID: number) {
        this.MdocsMoviesEditSubject.next(superID);
    }
    updateStatus(superID: number, status: string) {
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID + '&status=' + status);

    }
   
}
