import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Employee } from '../../models/employee.model';
import { EmployeeService } from '../../employee.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Address } from '../../models/address.model';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from 'src/app/admin/organization.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { ParameterService } from '../../parameter.service';
import { Parameter } from '../../models/parameter.model';
import { RoleService } from '../../role.service';
import { Role } from '../../models/role.model';
@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnDestroy, OnInit, AfterViewInit {

  empViewForm = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  employees: Array<Employee>;
  empFormDetails = [];
  orgs: Array<Organization> = [];
  orgEmployees: Array<{ org: number, employees: Array<Employee> }> = [];
  closeResult: string;
  designations: Array<Parameter> = [];
  departments: Array<Parameter> = []; roles: Array<Role>;
  constructor(private empService: EmployeeService, private orgService: OrganizationService, private modalService: NgbModal, private messageService: MessageService, private roleSevice: RoleService,
    private parameterService: ParameterService) { }

  ngOnInit() {
    this.roles = [];
    this.empFormDetails = [];
    this.employees = [];
    this.retrieveEmployees();
    this.getOrganizationUnits();
    this.getRoles();
    this.getDepartments();
    this.getDesignations();
    this.dtOptions = {
      dom: 'Bfrtip',
      "language": {
        "emptyTable": "No data available"
      },  
      searching: true,
      buttons: [
        {
          extend: 'print',
          text:      '  <i class="fa fa-print"></i> ',
          titleAttr: 'Print'
        },
        {
          extend: 'pdf',
          title: 'Employees',
          filename: 'Employees-'+this.formatDate(new Date()),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8,9]
          },
          text: '<i class="fa fa-file-pdf-o"></i> ',
          orientation: 'landscape',
          titleAttr: 'Pdf'

        }, {
          extend: 'excel',
          title: 'Employees',
          filename: 'Employees-'+this.formatDate(new Date()),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12,13,14,15,16]
          },
          text:'<i class="fa fa-file-excel-o"></i> ',
          titleAttr: 'Excel'
        }]


    };
    this.empService.employeeCreatedObservable.subscribe((resp: boolean) => {
      if (resp) {
        this.retrieveEmployees();
      }
    });
  }

  getOrganizationUnits() {
    // console.log('getOrganizationUnits');
    this.orgService.getOrganizationUnits().subscribe((response: any) => {
      console.log(response);
      this.orgs = response.MOrganiserDetails;
      for (const org of this.orgs) {
      //  this.getEmployees(org.orgId);
      }
    });

  }
  getEmployees(unitId: number) {
    this.empService.retrieveOrganizationEmployees(unitId).subscribe((response: any) => {
      this.orgEmployees.push({ org: unitId, employees: response });
    });
  }
  getOrganization(emp: Employee) {

    for (const org of this.orgs) {
      if (org.orgId === Number(emp.unitId)) {
        return org.orgCode;
      }
    } 
    return '';
  }

  retrieveEmployees() {
    this.empService.retrieveAllEmployees().subscribe((response: any) => {
      this.employees = response.MEmployeeDetails; 
      console.log(response);
      this.rerender();

    });

  }
  getRoles() {
    this.roleSevice.retrieveAllRoles().subscribe((response: any) => {
      this.roles = response.MRollsListDetails;
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  getDesignations() {
    this.parameterService.getDesignations().subscribe((response: any) => {
      this.designations = response.Configlst;
    });
  }

  getDepartments() {
    this.parameterService.getDepartments().subscribe((response: any) => {
      //console.log(response);
      this.departments = response.Configlst;
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  updateStatus(employee, status) {
    status = status == null || status == "true" ? "false" : "true";
    this.empService.empUpdateStatus(employee.employeeId, status).subscribe((emp) => {
      employee.isActive = status;
      this.messageService.add({ severity: 'success', summary: 'Employee Status update successfully', detail: '', sticky: false });
    });
  }
  openViewForm(content, employeeId: number) {

    // console.log(this.employees);
    //this.empViewForm = true;
    this.empService.getEmployeeForEdit(employeeId).subscribe((emp: Employee) => {
      this.modalService.open(content, { windowClass: "my-class" }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      this.empFormDetails = [{
        unitId: emp.unitId,
        employeeCode: emp.employeeCode,
        firstName: emp.firstName,
        middleName: emp.middleName,
        lastName: emp.lastName,
        gender: emp.gender,
        dob: emp.dob,
        departmentId: emp.departmentId,
        designationId: emp.designationId,
        email: emp.email,
        roleId: emp.roleId,
        mobile: emp.mobile,
        addressLine1: emp.addressLine1,
        addressLine2: emp.addressLine2,
        city: emp.city,
        state: emp.state,
        country: emp.country,
        zipCode: emp.zipCode,

      }];
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

  editEmployee(employeeId: number) {
    this.empService.editEmployee(employeeId);
  }
  getdepartmentName(dptId: number) {
    for (let dpt in this.departments) {
      if (dptId == this.departments[dpt].configId) {
        return this.departments[dpt].configVal;
      }
    }
    return "";
  }
  getdesignationName(designId: number) {
    for (let designation in this.designations) {
      if (this.designations[designation].configId == designId) {
        return this.designations[designation].configVal;
      }
    }
    return '';
  }
  getRolenName(roleID: number) {
    for (let role in this.roles) {
      if (this.roles[role].roleId == roleID) {
        return this.roles[role].roleName;
      }
    }
    return '';
  }
}
