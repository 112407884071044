import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnitwatermeterModel, Unittypemodel, Consumertypemodel, UnitwatermeterlistModel } from '../modles/unitwatermeter.model';
import { UnitWaterService } from '../services/unitwatermeter.services';
import { ConsumptionService } from '../services/consumption.services';
import { Consumptionmodel,UnitMasterListModel } from '../modles/consumptions.model';
import { MessageService } from 'primeng/api';
import { Organization } from '../../admin/models/organization.model';
import { OrganizationService } from '../../admin/organization.service';
@Component({
  selector: 'app-addconsumption',
  templateUrl: './addconsumption.component.html',
  styleUrls: ['./addconsumption.component.css']
})
export class AddconsumptionComponent implements OnInit {
  effForm: FormGroup;
  @Input() disabled = false;
  submitted = false; requiredmsg = false;
  selectedconsuptId = 0; status = true;
  unittypes: Unittypemodel[] = [];
  consumertypes: Consumertypemodel[] = [];
  unitwaterlst: UnitwatermeterlistModel[] = [];
  currentDate = new Date();
  organizations: Array<Organization>;
  constructor(private organizationService: OrganizationService,private fb: FormBuilder, private consumptserivce: ConsumptionService, private unitservice: UnitWaterService, private messageService: MessageService) { }

  ngOnInit() {
    this.createForm();
    const startDateIn = new Date();
    this.getOrganizations();
    this.effForm.get('FromDate').setValue(startDateIn);
    this.retrieveunitdetails();
    this.consumptserivce.ConsumptionActionObservable.subscribe((consuptId: number) => {
      if (consuptId) {
        this.selectedconsuptId = consuptId;
        this.consumptserivce.GetConsumptionDetail(consuptId).subscribe((effmodel: Consumptionmodel) => {
          console.log(effmodel);
          this.populateroadTaxtDetais(effmodel);

        });
      }
      this.requiredmsg = false;
    });
  }
  createForm() {
    this.effForm = this.fb.group({
      //vendorId: ['', Validators.required],
      UnitID: ['', Validators.required],
      FromDate: ['', Validators.required],
      Addition: ['', Validators.required],
      Reduction: ['', Validators.required],
      Reamrks: ['', Validators.required],
    });

    if (this.effForm.valid) {
      this.disabled = true;
    }
  }
  formreset() {
    this.effForm.reset();
    this.effForm.get("UnitID").setValue('');
  }
  retrieveunitdetails() {
    this.unitservice.getallUnitWaterList().subscribe((resp: any) => {
      if (resp) {
        this.unitwaterlst = resp.UnitMasterList;
      }
    });
  }
  populateroadTaxtDetais(getDetails: Consumptionmodel) {
    //this.roadtaxForm.get("vendorId").setValue(getDetails.vendorId);
    this.effForm.get("UnitID").setValue(getDetails.UnitID);
    //const roadTaxValidity = new Date(getDetails.roadTaxValidity);
    this.effForm.get("FromDate").setValue(this.formatDate(getDetails.FromDate));
    this.effForm.get("Addition").setValue(getDetails.Addition + "");
    this.effForm.get("Reduction").setValue(getDetails.Reduction + "");
    this.effForm.get("Reamrks").setValue(getDetails.Reamrks + "");
    this.selectedconsuptId = getDetails.ID;
    // this.status = getDetails.Status;

  }
  getOrganizations() {
    this.organizationService.getOrganizationUnits().subscribe((response: any) => {
      this.organizations = response.MOrganiserDetails;
    });
  }
  registerConsumption() {

    if (this.effForm.valid) {
      this.requiredmsg = false;
      const ConsuData = this.effForm.value;
      ConsuData.ID = this.selectedconsuptId;
      ConsuData.FromDate=this.formatDateval(ConsuData.FromDate);
      alert(ConsuData.FromDate);
     // EffData.Status = this.status;
      this.consumptserivce.addConsumption(ConsuData).subscribe((response: any) => {
        console.log(response);
        var statusval = this.selectedconsuptId == 0 ? "added" : "update";
        this.messageService.add({ severity: 'success', summary: 'Water Consumption ' + statusval + ' successfully', detail: '', sticky: false });
        this.selectedconsuptId = 0;
        this.formreset();
        this.consumptserivce.notifyConsumptionreated();
      });
    }
  }
  formatDateval(date) {
   
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

      return [year, month, day].join('-');
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}