import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UnitwatermeterModel } from '../modles/unitwatermeter.model';
@Injectable({
    providedIn: 'root'
})
export class UnitWaterService {

    private baseUrl = environment.waterMeter + 'api/Unit/';
    constructor(private httpClient: HttpClient) { }

    private UnitWaterreatedSubject = new Subject<any>();
    UnitWaterreatedObservable = this.UnitWaterreatedSubject.asObservable();

    private UnitWaterEditSubject = new Subject<any>();
    UnitWaterActionObservable = this.UnitWaterEditSubject.asObservable();

    addUnitWater(UnitWater: UnitwatermeterModel) {
        return this.httpClient.post(this.baseUrl+'SaveUnit', UnitWater);
    }
    getallUnitWaterList() {
        return this.httpClient.get(this.baseUrl +  "/GetUnitList");
    }
    getallUnitWaterType() {
        return this.httpClient.get(this.baseUrl +  "/GetUnitType");
    }

    getallConsumerType() {
        return this.httpClient.get(this.baseUrl +  "/GetConsumerType");
    }

    getUnittypes(){

    }

    getallUnitWaterbyunitId(unitID:number) {
        return this.httpClient.get(this.baseUrl + "/unitId?unitID="+unitID);
    }
    getallNamesUnitWater() {
        return this.httpClient.get(this.baseUrl + "/allNames");
    }
    getUnitWater(superID: number) {
        return this.httpClient.get(this.baseUrl + '/GetUnit?UnitID=' + superID);
    }
    getUnitWaterByRegId(RegId: number) {
        return this.httpClient.get(this.baseUrl + '/id?vehicleRegId=' + RegId);
    }
    getUnitWaterlistall(){
        return this.httpClient.get(this.baseUrl + "/allNames");
    }
    getUnitWaterbyUnitVendorID(unitID:number,firmID:number){
        return this.httpClient.get(this.baseUrl + "/getByUnitContractorId?unitID="+unitID+"&contractorId="+firmID);
    }
    getByVendorId(firmId:number){
        return this.httpClient.get(this.baseUrl + "/getByContractorId?contractorId="+firmId);
    }

    notifyUnitWaterreated() {
        this.UnitWaterreatedSubject.next(true);
    }
    editUnitWaterRequest(superID: number) {
        this.UnitWaterEditSubject.next(superID);
    }
    updateStatus(superID:number,status:string){
        return this.httpClient.get(this.baseUrl + '/updateStatus?contractorSupervisoryId=' + superID+'&status='+status);

    }
    uploadDocument(fromdata: FormData){
        return this.httpClient.post(this.baseUrl + '/uploadPhoto', fromdata, { observe: 'response' });
    }
}
