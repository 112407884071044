import { Address } from './address.model';

export class Employee {
	employeeId: number;
	companyId: string;
	employeeCode: string;
	firstName: string;
	middleName: string;
	lastName: string;
	userName:string;
	gender: string;
	dob: Date;
	department: string;
	designation: string;
	status: boolean;
	mobile: number;
	email: string;
	unitId: string;
	roleId: number;
	roleName: string;
	departmentId: string;
	designationId: string;
	aadharNumber:number;
	isActive:string;
	addressId: number;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	country: string;
	zipCode: string;
	isPrimary: string;
	addrType: string;

}
export class EmployeeModel{
	Emp_Id:number;
	Created_Date:string;
	Department_Id:number;
	Designation_Id:number;
	Date_Of_Birth:string;
	Email:string;
	Employee_Code:string;
	First_Name:string;
	Gender:string;
	Last_Name:string;
	Middle_Name:string;
	Mobile_Number:string;
	Unit_Id:number;
	Password:string;
	Roll_Id:number;
	User_Name:string;
	Active_Status:string;
	Address_Id:string;



}
